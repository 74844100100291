import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import Router from './router/router';
import { DialogServiceProvider, LoadingProvider, theme } from '@giflo/shared';
import { ClientPanelServiceProvider } from './providers/panel/PanelService';
import { ClientDialogServiceProvider } from './providers/dialog';

export const App = () => (
  <ChakraProvider theme={theme}>
    <LoadingProvider>
      <ClientPanelServiceProvider>
        <DialogServiceProvider>
          <ClientDialogServiceProvider>
            <Router></Router>
          </ClientDialogServiceProvider>
        </DialogServiceProvider>
      </ClientPanelServiceProvider>
    </LoadingProvider>
  </ChakraProvider>
);
