import React, { useEffect, useState } from 'react';
import { Stack, Flex, Box } from '@chakra-ui/react';
import {
  AppText,
  Facility,
  AppCarousel,
  AppButton,
  AppColors,
  AppCalendarButton,
  Time,
} from '@giflo/shared';
import AppTextRenderer from '@giflo/shared/lib/components/app-text-renderer/app-text-renderer';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { MdPeopleOutline } from 'react-icons/md';
import { format } from 'date-fns';

type FacilityDetailProps = {
  facility: Facility;
  bookingDate?: Date | number;
  bookingStart?: Time;
  bookingEnd?: Time;
  showAddCalendar?: boolean;
  handleSubmit: () => void;
  handleBooking: boolean;
};

const FacilityDetails: React.FC<FacilityDetailProps> = ({
  facility,
  bookingDate,
  bookingStart,
  bookingEnd,
  showAddCalendar = false,
  handleBooking,
  handleSubmit,
}) => {
  // const [start, setStart] = useState(bookingStart);
  // const [end, setEnd] = useState(bookingEnd);
  // const [date, setDate] = useState(bookingDate);

  // useEffect(() => {
  //   if (bookingStart) {
  //     setStart(bookingStart);
  //   }
  // }, [bookingStart]);

  // useEffect(() => {
  //   if (bookingEnd) {
  //     setEnd(bookingEnd);
  //   }
  // }, [bookingEnd]);

  // useEffect(() => {
  //   if (bookingDate) {
  //     setDate(bookingDate);
  //   }
  // }, [bookingDate]);

  return (
    <>
      <Stack spacing={4} color={{ base: 'white' }}>
        <AppCarousel facility={facility} />
        <AppText fontSize={'xl'} fontWeight={'500'}>
          {facility.name}
        </AppText>
        <Flex>
          <AppTextRenderer markdown={facility!.description} />
        </Flex>
        <Flex direction={'column'} gap={3}>
          <Flex gap={2} justify={'flex-start'}>
            <MdPeopleOutline />
            <AppText>{facility.capacity}</AppText>
          </Flex>
          <Flex gap={2}>
            <AiOutlineClockCircle />
            <AppText>{`${facility.start?.hour}:${
              facility.start?.minute === 0 ? '00' : facility.start?.minute
            } - ${facility.end?.hour}:${
              facility.end?.minute === 0 ? '00' : facility.end?.minute
            }`}</AppText>
          </Flex>
          <Flex w={'full'} color={'white'} gap={3}>
            {facility.availableDaysOfTheWeek?.map((x) => {
              return <AppText color={'white'}>{x.day}</AppText>;
            })}
          </Flex>
        </Flex>
        <Flex direction={'column'} gap={2}>
          <AppText fontSize={'md'} fontWeight={'500'}>
            Line Items
          </AppText>
          {facility!.lineItems?.map((x) => {
            return <AppText key={x.lineItem}>{x.lineItem}</AppText>;
          })}
        </Flex>
        {bookingStart && bookingDate && bookingEnd && (
          <Flex
            w={'full'}
            // h={'80px'}
            justify={'end'}
            p={2}
            bgColor={AppColors.tertiary}
            direction={'column'}
          >
            {showAddCalendar && <AppCalendarButton />}

            <Flex direction={'column'} gap={3} w={'full'} p={4} color={'white'}>
              <AppText fontSize={'2xl'} fontWeight={'600'}>
                Booking Details
              </AppText>
              <Flex w={'full'} justify={'space-between'}>
                <AppText>
                  {format(new Date(bookingDate), 'dd MMM yyyy')}
                </AppText>
                <Flex w={'full'} align={'center'}>
                  <Flex alignItems={'center'}>
                    <AiOutlineClockCircle />
                    <AppText ml={2} fontWeight={'bold'}>
                      {`${bookingStart?.hour}:${
                        bookingStart?.minute === 0 ? '00' : bookingStart?.minute
                      } - ${bookingEnd?.hour}:${
                        bookingEnd?.minute === 0 ? '00' : bookingEnd?.minute
                      }`}
                    </AppText>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}

        <AppButton
          display={handleBooking ? 'flex' : 'none'}
          bgColor={AppColors.tertiary}
          color={'white'}
          borderRadius={0}
          onClick={() => {
            handleSubmit();
          }}
        >
          Confirm Booking
        </AppButton>
      </Stack>
    </>
  );
};
export default FacilityDetails;
