import React, { useEffect, useState } from 'react';
import {
  SelectListFilter,
  AppInput,
  AppButton,
  AppColors,
  AppDatePicker,
  SelectOption,
  RoomType,
} from '@giflo/shared';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { FacilitySearchFormData } from '@giflo/shared';
import { FormLabel, Flex, useBreakpointValue } from '@chakra-ui/react';
import { addDays } from 'date-fns';
import { UTCDate } from '@date-fns/utc';

const facilitySearchFormDefaultValues: FacilitySearchFormData = {
  date: new Date(),
  capacity: 1,
  duration: undefined,
};

const facilitySearchFormDataSchema = yup.object({
  date: yup.date(),
  capacity: yup.number(),
  duration: yup.number(),
});

type FacilitySearchFormProps<T> = {
  form?: FacilitySearchFormData;
  onSearch?: () => void;
  handleFirstAvailableSlot: () => void;
  roomType?: RoomType;
} & BaseFormProps<T>;

const FacilitySearchForm: React.FC<
  FacilitySearchFormProps<FacilitySearchFormData>
> = ({ form, onSubmit, handleFirstAvailableSlot, roomType }) => {
  const [defaultDurationOption, setDefaultDurationOption] =
    useState<SelectOption>();

  const {
    control: control,
    formState: { isValid, errors },
    handleSubmit,
    setValue,
  } = useForm<FacilitySearchFormData>({
    defaultValues: form || facilitySearchFormDefaultValues,
    resolver: yupResolver(facilitySearchFormDataSchema),
    mode: 'onChange',
  });

  const durations = [15, 30, 45, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [durationOptions, setDuationOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (roomType === RoomType.MEETING_ROOM) {
      const selectors: SelectOption[] = [];
      durations.map((x) => {
        selectors.push({
          label: x > 12 ? `${x}min` : `${x} hr`,
          value: x,
        });
      });
      setDuationOptions(selectors);

      setDefaultDurationOption({
        label: `15 min`,
        value: 15,
      } as SelectOption);
    }
  }, [roomType]);

  const handleDurationSelect = (item: SelectOption) => {
    // debugger;
    setValue('duration', item.value);
  };

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  return (
    <>
      {roomType === RoomType.MEETING_ROOM ? (
        <Flex
          gap={{ base: 3, lg: 6 }}
          bgColor={AppColors.formBackground}
          direction={{ base: 'column', lg: 'row' }}
          p={4}
          w={'full'}
          align={'end'}
          justify={'center'}
        >
          <Flex direction={'column'} w={'full'}>
            <FormLabel color={'white'} fontSize={'sm'} fontWeight={'600'}>
              DATE
            </FormLabel>
            <AppDatePicker<FacilitySearchFormData>
              name="date"
              minDate={addDays(new UTCDate(), -1)}
              control={control}
              error={undefined}
              label=""
              color={'white'}
            />
          </Flex>
          <Flex direction={'column'} w={'full'}>
            <FormLabel color={'white'} fontSize={'sm'} fontWeight={'600'}>
              CAPACITY
            </FormLabel>
            <AppInput<FacilitySearchFormData>
              name="capacity"
              error={errors.capacity}
              control={control}
              label=""
              inputType="number"
              color={'white'}
            />
          </Flex>
          <Flex direction={'column'} w={'full'}>
            <FormLabel color={'white'} fontSize={'sm'} fontWeight={'600'}>
              DURATION
            </FormLabel>
            <SelectListFilter
              mt={2}
              color={'white'}
              options={durationOptions}
              name="duration"
              isInModal
              isMulti={false}
              placeholder="Duration"
              showClear={false}
              defaultOption={defaultDurationOption}
              onSelectionChange={(item: SelectOption[]) => {
                handleDurationSelect(item[0]);
              }}
            />
          </Flex>
          <Flex direction={'column'} w={'full'} gap={3}>
            <AppButton
              bgColor={AppColors.tertiary}
              color={'white'}
              onClick={handleSubmit(onSubmit)}
              borderRadius={0}
              size={'md'}
            >
              Search
            </AppButton>
            {/* <AppButton
              bgColor={AppColors.tertiary}
              color={'white'}
              borderRadius={0}
              size={'md'}
              onClick={() => handleFirstAvailableSlot()}
            >
              First Available Slot
            </AppButton> */}
          </Flex>
        </Flex>
      ) : (
        <Flex
          gap={{ base: 3, lg: 6 }}
          bgColor={AppColors.formBackground}
          direction={{ base: 'column', lg: 'row' }}
          p={4}
          w={'full'}
          align={'end'}
          justify={'center'}
        >
          <Flex direction={'column'} w={'full'} justify={'end'}>
            <FormLabel color={'white'} fontSize={'sm'} fontWeight={'600'}>
              DATE
            </FormLabel>
            <AppDatePicker<FacilitySearchFormData>
              name="date"
              minDate={addDays(new UTCDate(), -1)}
              control={control}
              error={undefined}
              label=""
              color={'white'}
            />
          </Flex>
          <Flex direction={'column'} w={'full'} gap={3}>
            <AppButton
              bgColor={AppColors.tertiary}
              color={'white'}
              onClick={handleSubmit(onSubmit)}
              borderRadius={0}
              size={'md'}
            >
              Search
            </AppButton>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default FacilitySearchForm;
