import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Activity } from '@giflo/shared';
import { RootState } from '../store';
import { activityApi } from '../api/activityApi';

interface ActivityState {
  currentActivity: Activity | undefined;
  activities: Activity[] | undefined;
}

const initialState: ActivityState = {
  currentActivity: undefined,
  activities: [],
};

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      activityApi.endpoints.fetchAllActivities.matchFulfilled,
      (state, { payload }) => {
        return { ...state, activities: payload };
      }
    );
  },
  reducers: {
    setCurrentActivity: (state, action: PayloadAction<Activity>) => {
      return {
        ...state,
        currentActivity: action.payload,
      };
    },
    clearCurrentActivity: (state) => { 
      return {
        ...state,
        currentActivity: undefined,
      };
    },
  },
});

export const { setCurrentActivity, clearCurrentActivity } =
  activitySlice.actions;

export const selectActivities = (state: RootState) => state.activity.activities;
export const selectCurrentActivity = (state: RootState) =>
  state.activity.currentActivity;
