import { createApi } from '@reduxjs/toolkit/query/react';
import {
  FacilityTypes,
  Organization,
  OrganizationEditForm,
  OrganizationFacilityDto,
  OrganizationFacilityMappingRequest,
  OrganizationFormData,
} from '@giflo/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationsApi = createApi({
  reducerPath: 'organizationsApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizations: build.query<Organization[], any>({
      query: () => ({
        url: '/organization',
        method: 'GET',
      }),
      transformResponse: (response: Organization[]) => {
        return response?.filter((x) => x.isActive === true);
      },
    }),
    fetchOrganizationCount: build.query<number, any>({
      query: () => ({
        url: '/organization/count',
        method: 'GET',
      }),
      transformResponse: (response: number) => {
        return response;
      },
    }),
    fetchTopOrganization: build.query<Organization, any>({
      query: () => ({
        url: `/organization/top/organization`,
        method: 'GET',
      }),
    }),
    fetchOganizationFacilities: build.query<OrganizationFacilityDto[], string>({
      query: (id) => ({
        url: `/organization/facilities/${id}`,
        method: 'GET',
      }),
    }),
    fetchOrganizationFacilityById: build.query<OrganizationFacilityDto, string>({
      query: (id: string) => ({
        url: `/organization/facilities/${id}`,
        method: 'GET',
      }),
    }),
    fetchOrganizationById: build.query<Organization, string>({
      query: (id) => ({
        url: `/organization/${id}`,
        method: 'GET',
      }),
    }),
    allocateOrganizationFacilities: build.mutation<
      Organization,
      OrganizationFacilityMappingRequest
    >({
      query: (body) => ({
        url: `/organization/facility/mapping`,
        method: 'POST',
        body: body,
      }),
    }),
    fetchOrganizationFacilityTypes: build.query<FacilityTypes, string>({
      query: (orgId) => ({
        url: `/organization/facility/types/${orgId}`,
        method: 'GET'
      })
    }),
    createOrganization: build.mutation<Organization, OrganizationFormData>({
      query: (body) => ({
        url: '/organization/create',
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: Organization) => {
        return response;
      },
    }),
    updateOrganization: build.mutation<Organization, OrganizationEditForm>({
      query: (body) => ({
        url: `/organization/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      transformResponse: (response: Organization) => {
        return response;
      },
    }),
    deleteOrganization: build.mutation<string, string>({
      query: (id) => ({
        url: `/organization/delete/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteOrganizationOverride: build.mutation<string, string>({
      query: (id) => ({
        url: `/organization/delete/${id}/override`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchAllOrganizationsQuery,
  useFetchOrganizationCountQuery,
  useFetchTopOrganizationQuery,
  useLazyFetchAllOrganizationsQuery,
  useFetchOrganizationByIdQuery,
  useLazyFetchOrganizationFacilityTypesQuery,
  useFetchOrganizationFacilityTypesQuery,
  useFetchOganizationFacilitiesQuery,
  useFetchOrganizationFacilityByIdQuery,
  useAllocateOrganizationFacilitiesMutation,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useDeleteOrganizationOverrideMutation,
} = organizationsApi;
