import React, { useState } from 'react';
import {
  DarkMode,
  LightMode,
  Link,
  Stack,
  Flex,
  Box,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { BaseFormProps } from './base-forms';
import {
  AppButton,
  AppColors,
  AppInput,
  AppText,
  useLoading,
} from '@giflo/shared';
import { useRequestPasswordResetByEmailMutation } from '../../store/api/authenticationApi';

export type LoginFormData = {
  username: string;
  password: string;
};

export const loginFormDataDefaultValues: LoginFormData = {
  username: '',
  password: '',
};

const loginFormDataSchema = yup.object({
  username: yup.string().required('Field is required'),
  password: yup.string().required('Field is required'),
});

type LoginFormProps<T> = BaseFormProps<T>;

const LoginForm: React.FC<LoginFormProps<LoginFormData>> = ({
  isLoading,
  onSubmit,
}) => {
  const toast = useToast();
  const { setLoading } = useLoading();

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<LoginFormData>({
    defaultValues: loginFormDataDefaultValues,
    resolver: yupResolver(loginFormDataSchema),
    mode: 'all',
  });

  const [requestPasswordReset] = useRequestPasswordResetByEmailMutation();

  const handleFormSubmit = (formData: LoginFormData) => {
    onSubmit(formData);
  };

  const formValues = getValues();

  const [passwordInput, setPasswordInput] = useState<string>('block');

  const handleSendEmail = async (email: string) => {
    try {
      setLoading(true);
      await requestPasswordReset(email);
      setLoading(false);
      toast({
        title: 'Password reset request',
        status: 'success',
        description: `We've sent you an email, please follow the link to reset your password.`,
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      setLoading(false);
      toast({
        title: 'Password reset request issue',
        description:
          "We've run into an issue sending your reset password email, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack mt={8} spacing={4}>
      <LightMode>
        <AppInput<LoginFormData>
          control={control}
          name="username"
          label="Email"
          error={errors.username}
          color={'white'}
        />
        <Flex display={passwordInput}>
          <AppInput<LoginFormData>
            inputType="password"
            control={control}
            label="Password"
            error={errors.password}
            name="password"
            color={'white'}
          />
        </Flex>
        <Stack spacing={4}>
          <Flex display={passwordInput}>
            <AppText
              textDecoration={'underline'}
              w={'full'}
              color={'white'}
              onClick={() => {
                setPasswordInput('none');
              }}
              _hover={{
                cursor: 'pointer',
              }}
            >
              Forgot Password
            </AppText>
          </Flex>
          <Box w={'full'} display={passwordInput}>
            <AppButton
              bgColor={AppColors.primary}
              w={'full'}
              borderRadius={0}
              color={'white'}
              isLoading={isLoading}
              loadingText="Logging in"
              isDisabled={!isValid}
              onClick={handleSubmit(handleFormSubmit)}
              mb={2}
            >
              Login
            </AppButton>
          </Box>
          <Flex
            display={passwordInput === 'none' ? 'block' : 'none'}
            w={'full'}
          >
            <AppButton
              bgColor={AppColors.tertiary}
              color={'white'}
              w={'full'}
              borderRadius={0}
              onClick={() => {
                handleSendEmail(formValues.username);
              }}
            >
              Send Email
            </AppButton>

            <AppText
              mt={8}
              textDecoration={'underline'}
              color={'white'}
              w={'full'}
              onClick={() => {
                setPasswordInput('block');
              }}
              _hover={{
                cursor: 'pointer',
              }}
            >
              Back to login
            </AppText>
          </Flex>
        </Stack>
      </LightMode>
    </Stack>
  );
};

export default LoginForm;
