import React, { useState } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import { RoutesList } from '../../router/router';
import FacilityDetails from '../../components/forms/facility-details';
import Header from '../../components/layouts/header';
import { useAppSelector } from '../../store/store';
import {
  selectBookingDay,
  selectedStartTime,
  selectedEndTime,
  selectedFacility,
} from '../../store/slices/booking.slice';
import {
  AppButton,
  AppColors,
  BookingForm,
  Time,
  useLoading,
} from '@giflo/shared';
import { selectUser } from '../../store/slices/authentication.slice';
import { useCreateOrganizationBookingMutation } from '../../store/api/organizationBookingApi';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const BookingConfirmation: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const user = useAppSelector(selectUser);
  const facility = useAppSelector(selectedFacility);
  const bookingDate = useAppSelector(selectBookingDay);
  const bookingStart = useAppSelector(selectedStartTime);
  const bookingEnd = useAppSelector(selectedEndTime);

  const [bookingConfirmed, setBookingConfirmed] = useState<boolean>(false);

  const [saveBookingTrigger] = useCreateOrganizationBookingMutation();

  const handleConfirmBooking = async () => {
    try {
      setLoading(true);
      let endTime: Time = {
        hour: bookingEnd?.hour ?? 10,
        minute: bookingEnd?.minute ?? 0,
      };

      const date = new Date(bookingDate!);

      const newBooking: BookingForm = {
        organizationId: user?.organizationId ?? '',
        facilityId: facility?.id ?? '',
        day: date,
        start: bookingStart,
        end: endTime,
      };

      await upsertBooking(newBooking);
      setBookingConfirmed(true);
      setLoading(false);
    } catch (e) {
      toast({
        title: 'Booking save issue',
        description:
          "We've run into an issue saving the booking you created, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const upsertBooking = async (formData: BookingForm) => {
    setLoading(true)
    try {
      const bookingId = await saveBookingTrigger({
        organizationId: formData.organizationId,
        facilityId: formData.facilityId,
        day: format(new Date(formData.day!), 'yyyyMMdd'),
        start: formData.start,
        end: formData.end,
      }).unwrap();

      if (bookingId) {
        toast({
          title: 'Booking saved!',
          status: 'success',
          description: `We've saved the Booking you created`,
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Booking slot not available',
          description:
            'It seems someone booked your slot before you could confirm, please go back & select another available slot.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (e) {
      toast({
        title: 'Booking save issue',
        description:
          "We've run into an issue saving the booking you created, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false)
    }
    setLoading(false)
  };

  return (
    <BaseLayout>
      <Flex direction={'column'} w={'full'} overflowY={'scroll'}>
        <Header backButton href={RoutesList.BookingSearch} />
        <Flex direction={'column'} pt={6} pb={'60px'} h={'auto'} w={'full'}>
          <Flex
            direction={'column'}
            bgColor={AppColors.formBackground}
            color={'white'}
            mx={{ base: 4, lg: 6 }}
            p={3}
            alignSelf={'center'}
            w={{ base: '90%', lg: '700px' }}
            gap={4}
          >
            {facility && (
              <Flex w={'full'} direction={'column'}>
                <FacilityDetails
                  facility={facility}
                  bookingDate={bookingDate}
                  bookingStart={bookingStart}
                  bookingEnd={bookingEnd}
                  showAddCalendar={bookingConfirmed}
                  handleSubmit={() => {
                    handleConfirmBooking();
                  }}
                  handleBooking={!bookingConfirmed}
                />
              </Flex>
            )}

            <AppButton
              display={bookingConfirmed ? 'flex' : 'none'}
              bgColor={AppColors.tertiary}
              color={'white'}
              borderRadius={0}
              onClick={() => {
                navigate(RoutesList.Bookings);
              }}
            >
              My bookings
            </AppButton>
          </Flex>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default BookingConfirmation;
