import React, { useEffect, useState } from 'react';
import { Flex, FormLabel, Image } from '@chakra-ui/react';
import {
  AppText,
  Time,
  AppColors,
  SelectOption,
  RoomType,
  AvailableBookingFacility,
} from '@giflo/shared';
import { useDispatch } from 'react-redux';
import {
  selectBookingDay,
  setBookingEnd,
  setBookingStart,
} from '../../store/slices/booking.slice';
import { useAppSelector } from '../../store/store';
import { format } from 'date-fns';

export type TimeAvailabilityProps = {
  formDisplay: string;
  availableBookingFacility: AvailableBookingFacility;
  timeSlotSelect: (item?: Time) => void;
  handleClubHouseEnd: (item: Time) => void;
};

const TimeAvailabilityForm: React.FC<TimeAvailabilityProps> = ({
  formDisplay,
  availableBookingFacility,
  timeSlotSelect,
  handleClubHouseEnd,
}) => {
  const dispatch = useDispatch();
  const mainImage = availableBookingFacility.facility.images.filter(
    (x) => x.isMain
  );
  const bookingDate = useAppSelector(selectBookingDay);

  const [availableMorningSlots, setAvailableMorningSlots] = useState<Time[]>(
    []
  );
  const [availableAfternoonSlots, setAvailableAfternoonSlots] =
    useState<Time[]>();

  useEffect(() => {
    if (
      availableBookingFacility &&
      availableBookingFacility.slots &&
      availableBookingFacility.facility.roomType !== RoomType.CLUB_HOUSE
    ) {
      setAvailableMorningSlots([]);
      const morningSlots: Time[] = [];
      for (const slot of availableBookingFacility.slots) {
        if (slot.start.hour < 12) {
          morningSlots.push({
            hour: slot.start.hour,
            minute: slot.start.minute,
          });
        }
      }

      setAvailableMorningSlots(morningSlots);

      setAvailableAfternoonSlots([]);
      const afternoonSlots: Time[] = [];
      for (const slot of availableBookingFacility.slots) {
        if (slot.start.hour >= 12) {
          afternoonSlots.push({
            hour: slot.start.hour,
            minute: slot.start.minute,
          });
        }
      }
      setAvailableAfternoonSlots(afternoonSlots);
    } else {
      const startHour = availableBookingFacility.facility.start?.hour ?? 10;
      const endHour = availableBookingFacility.facility.end?.hour ?? 19;
      const selectors: SelectOption[] = [];
      for (let i = startHour; i < endHour; i++) {
        selectors.push({
          label: `${i}: 00`,
          value: { hour: i, minute: 0 },
        });
      }
      setStartTimeOptions(selectors);
    }
  }, [availableBookingFacility]);

  const [startTimeOptions, setStartTimeOptions] = useState<SelectOption[]>([]);
  const [endTimeOptions, setEndTimeOptions] = useState<SelectOption[]>([]);
  const [endTimeVisibility, setEndTimeVisibility] = useState<string>('none');
  const [selectedStartTime, setSelectedStartTime] = useState<Time | undefined>(
    undefined
  );

  const handleUpdateEndTimes = async (time: Time) => {
    const startHour = time.hour + 1;
    const endHour = availableBookingFacility.facility.end?.hour ?? 19;
    const selectors: SelectOption[] = [];
    for (let i = startHour; i <= endHour; i++) {
      selectors.push({
        label: `${i}: 00`,
        value: { hour: i, minute: 0 },
      });
    }
    setSelectedStartTime(time);
    setEndTimeOptions(selectors);
  };

  return (
    <>
      {availableBookingFacility.facility.roomType !== RoomType.CLUB_HOUSE ? (
        <Flex
          p={{ base: 4, lg: 0 }}
          gap={4}
          bgColor={{ base: AppColors.formBackground, lg: 'transparent' }}
          w={'full'}
          color={'white'}
          justify={'center'}
          direction={'column'}
          display={formDisplay}
        >
          <Image
            src={mainImage[0].imageUrl}
            w={'auto'}
            h={{ base: '180px', lg: 'inherit' }}
          />
          <Flex
            direction={'column'}
            align={{ base: 'start', lg: 'center' }}
            justify={'center'}
            w={'full'}
            gap={3}
            color={'white'}
            fontWeight={'600'}
          >
            <AppText fontSize={'2xl'} fontWeight={'bold'}>
              {availableBookingFacility.facility.name}
            </AppText>
            <AppText fontWeight={'600'}>
              {bookingDate && format(new Date(bookingDate), 'dd MMM yyyy')}
            </AppText>
            <AppText fontWeight={'600'}>
              {bookingDate && format(bookingDate, 'eeee')}
            </AppText>
            <AppText
              fontWeight={'600'}
            >{`${availableBookingFacility.facility.capacity} People`}</AppText>
          </Flex>
          <Flex p={6} w={'full'} justify={'space-between'} gap={3}>
            <Flex
              direction={'column'}
              align={'center'}
              justify={'start'}
              gap={{ base: 3, lg: 4 }}
              color={'black'}
              w={'full'}
            >
              <AppText fontWeight={'bold'} color={'white'}>
                Morning
              </AppText>
              {availableMorningSlots?.map((slot, i) => {
                return (
                  <Flex
                    key={i}
                    w={'full'}
                    bgColor={
                      i % 2 === 0 ? AppColors.tertiary : AppColors.appBorder
                    }
                    color={i % 2 === 0 ? 'white' : 'black'}
                    p={4}
                    justify={'center'}
                    _hover={{
                      transform: 'translateY(-2px)',
                      transition: '0.2s',
                      boxShadow: 'lg',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      timeSlotSelect(slot);
                    }}
                  >
                    <AppText fontWeight={'bold'}>
                      {slot.hour.toString().length === 2
                        ? slot.hour
                        : `0${slot.hour}`}
                      :
                      {slot.minute.toString().length === 2
                        ? slot.minute
                        : `${slot.minute}0`}
                    </AppText>
                  </Flex>
                );
              })}
            </Flex>
            <Flex
              direction={'column'}
              align={'center'}
              justify={'start'}
              gap={{ base: 3, lg: 4 }}
              w={'full'}
            >
              <AppText fontWeight={'bold'} color={'white'}>
                Afternoon
              </AppText>
              {availableAfternoonSlots?.map((slot, i) => {
                return (
                  <Flex
                    key={i}
                    bgColor={
                      i % 2 === 0 ? AppColors.tertiary : AppColors.appBorder
                    }
                    color={i % 2 === 0 ? 'white' : 'black'}
                    p={4}
                    w={'full'}
                    justify={'center'}
                    _hover={{
                      transform: 'translateY(-2px)',
                      transition: '0.2s',
                      boxShadow: 'lg',
                      cursor: 'pointer',
                    }}
                    onClick={() => timeSlotSelect(slot)}
                  >
                    <AppText fontWeight={'bold'}>
                      {slot.hour.toString().length === 2
                        ? slot.hour
                        : `0${slot.hour}`}
                      :
                      {slot.minute.toString().length === 2
                        ? slot.minute
                        : `${slot.minute}0`}
                    </AppText>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex
          p={{ base: 4, lg: 0 }}
          gap={{ base: 8, lg: 4 }}
          bgColor={{ base: AppColors.formBackground, lg: 'transparent' }}
          w={'full'}
          color={'white'}
          justify={'center'}
          direction={'column'}
          display={formDisplay}
        >
          <Image
            src={mainImage[0].imageUrl}
            w={'auto'}
            h={{ base: '180px', lg: 'inherit' }}
          />
          <Flex
            direction={'column'}
            align={{ base: 'start', lg: 'center' }}
            justify={'center'}
            w={'full'}
            gap={3}
            color={'white'}
            fontWeight={'600'}
          >
            <AppText fontSize={'2xl'} fontWeight={'bold'}>
              {availableBookingFacility.facility.name}
            </AppText>
            <AppText fontWeight={'600'}>
              {bookingDate && format(new Date(bookingDate), 'dd MMM yyyy')}
            </AppText>
            <AppText fontWeight={'600'}>
              {bookingDate && format(bookingDate, 'eeee')}
            </AppText>
            <AppText
              fontWeight={'600'}
            >{`${availableBookingFacility.facility.capacity} People`}</AppText>
          </Flex>
          <Flex direction={'row'} w={'full'} gap={3} justify={'center'}>
            <Flex direction={'column'} w={'full'} gap={3}>
              <FormLabel color={'white'} fontSize={'sm'} fontWeight={'600'}>
                STARTING
              </FormLabel>
              <Flex gap={3} w={'full'} direction={'column'}>
                {startTimeOptions.map((time, i) => {
                  return (
                    <Flex
                      key={i}
                      bgColor={
                        selectedStartTime?.hour === time.value.hour
                          ? 'gray'
                          : i % 2 === 0
                          ? AppColors.tertiary
                          : AppColors.appBorder
                      }
                      color={i % 2 === 0 ? 'white' : 'black'}
                      p={4}
                      w={'full'}
                      justify={'center'}
                      _hover={{
                        transform: 'translateY(-2px)',
                        transition: '0.2s',
                        boxShadow: 'lg',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleUpdateEndTimes(time.value);
                        setEndTimeVisibility('flex');
                        dispatch(setBookingStart(time.value));
                      }}
                    >
                      <AppText fontWeight={'bold'}>
                        {time.value.hour}
                        :00
                      </AppText>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
            <Flex direction={'column'} w={'full'} gap={3}>
              <FormLabel
                alignSelf={'end'}
                color={'white'}
                fontSize={'sm'}
                fontWeight={'600'}
              >
                ENDING
              </FormLabel>
              <Flex
                gap={3}
                w={'full'}
                direction={'column'}
                display={endTimeVisibility}
              >
                {endTimeOptions.map((time, i) => {
                  return (
                    <Flex
                      key={i}
                      bgColor={
                        i % 2 === 0 ? AppColors.tertiary : AppColors.appBorder
                      }
                      color={i % 2 === 0 ? 'white' : 'black'}
                      p={4}
                      w={'full'}
                      justify={'center'}
                      _hover={{
                        transform: 'translateY(-2px)',
                        transition: '0.2s',
                        boxShadow: 'lg',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        dispatch(setBookingEnd(time.value));
                        handleClubHouseEnd(time.value);
                      }}
                    >
                      <AppText fontWeight={'bold'}>
                        {availableBookingFacility.facility.end?.hour.toString()
                          .length === 2
                          ? time.value.hour
                          : `${time.value.hour}`}
                        :00
                      </AppText>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default TimeAvailabilityForm;
