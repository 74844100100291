import React from 'react';
import { Flex } from '@chakra-ui/react';
import { RoutesList } from '../../router/router';
import BaseLayout from '../../components/layouts/base-layout';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { selectUser, logout } from '../../store/slices/authentication.slice';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppText,
  User,
  useDialog,
} from '@giflo/shared';
import { useNavigate } from 'react-router-dom';
import { selectColor } from '../../store/slices/iconColor.slice';
import { useDispatch } from 'react-redux';
import Header from '../../components/layouts/header';

const UserProfile: React.FC = () => {
  const user = useSelector(selectUser);
  const color = useSelector(selectColor) as string;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dialog = useDialog();

  const onLogout = () => {
    dispatch(logout());
    navigate(RoutesList.Index);
  };

  const handleConfirm = (user: User) => {
    dialog({
      title: 'Logout',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to logout?"
            onConfirm={async () => {
              onSubmit();
              onLogout();
              navigate(RoutesList.Index);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  return (
    <BaseLayout>
      <Flex
        direction={'column'}
        gap={4}
        h={'auto'}
        w={'full'}
        overflow={'hidden'}
        pb={12}
      >
        <Flex direction={'column'} h={'auto'}>
          <Header backButton href={RoutesList.Bookings} />
          <Flex minH={'120px'} align={'center'} justify={'center'}>
            <Flex
              borderRadius={'100%'}
              p={4}
              bgColor={color}
              border={`5px solid white`}
            >
              <AppText
                fontSize={'md'}
                color={'white'}
                fontWeight={'500'}
              >{`${user?.firstName[0]}${user?.lastName[0]}`}</AppText>
            </Flex>
          </Flex>
          <Flex
            direction={'column'}
            bgColor={AppColors.formBackground}
            color={'white'}
            mx={{ base: 4, lg: 6 }}
            p={3}
            alignSelf={'center'}
            w={{ base: '90%', lg: '700px' }}
            gap={4}
          >
            <Flex
              justify={'space-between'}
              h={'40px'}
              align={'center'}
              borderBottom={`1px solid ${AppColors.appBorder}`}
            >
              <AppText>First Name:</AppText>
              <AppText>{user?.firstName}</AppText>
            </Flex>
            <Flex
              justify={'space-between'}
              h={'40px'}
              align={'center'}
              borderBottom={`1px solid ${AppColors.appBorder}`}
            >
              <AppText>Last Name:</AppText>
              <AppText>{user?.lastName}</AppText>
            </Flex>
            <Flex
              justify={'space-between'}
              h={'40px'}
              align={'center'}
              borderBottom={`1px solid ${AppColors.appBorder}`}
            >
              <AppText>Email address:</AppText>
              <AppText>{user?.email}</AppText>
            </Flex>
            <Flex justify={'space-between'} h={'40px'} align={'center'}>
              <AppText>Organization:</AppText>
              <AppText>{user?.organization.name}</AppText>
            </Flex>
            <AppButton
              mx={{ base: 4, lg: 0 }}
              bgColor={AppColors.tertiary}
              color={'white'}
              onClick={() => handleConfirm(user!)}
              borderRadius={0}
            >
              Sign Out
            </AppButton>
          </Flex>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default UserProfile;
