import { createApi } from '@reduxjs/toolkit/query/react';
import { User, UserUpsertRequest } from '@giflo/shared';
import { AppFetchBaseQuery } from './apiBase';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllusers: build.query<User[], {}>({
      query: () => ({
        url: '/user',
        method: 'GET',
      }),
      transformResponse: (response: User[]) => {
        return response;
      },
    }),
    fetchUserByOrganizationId: build.query<User[], string>({
      query: (orgId) => ({
        url: `/user/by/organization/${orgId}`,
        method: 'GET',
      }),
    }),
    fetchUserById: build.query<User, string>({
      query: (id) => ({
        url: `/user/${id}`,
        method: 'GET',
      }),
    }),
    createUser: build.mutation<User, UserUpsertRequest>({
      query: (body) => ({
        url: `/user/create`,
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: User) => {
        return response;
      },
    }),
    updateUser: build.mutation<User, UserUpsertRequest>({
      query: (body) => ({
        url: `/user/update`,
        method: 'PUT',
        body: body,
      }),
      transformResponse: (response: User) => {
        return response;
      },
    }),
    deleteUser: build.mutation<string, string>({
      query: (id) => ({
        url: `/user/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchAllusersQuery,
  useFetchUserByOrganizationIdQuery,
  useFetchUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi;
