import React, { useState } from 'react';
import {
  Flex,
  useBreakpointValue,
  Box,
  Grid,
  GridItem,
  Image,
  IconButton,
} from '@chakra-ui/react';
import {
  AppColors,
  AppText,
  AvailableBookingFacility,
  AvailableBookingSlotResponse,
  RoomType,
  Time,
  useLoading,
} from '@giflo/shared';
import BaseLayout from '../../components/layouts/base-layout';
import FacilitySearchForm from '../../components/forms/handle-facility-search';
import { RoutesList } from '../../router/router';
import { AppFacilityCard } from '../../components/app/facility-card/app-facility-card';
import { Facility } from '@giflo/shared';
import FacilityDetails from '../../components/forms/facility-details';
import { useFetchAvailableTimeSlotsMutation } from '../../store/api/organizationBookingApi';
import Header from '../../components/layouts/header';
import TimeAvailabilityForm from '../../components/forms/time-availability-form';
import logo from '../../assets/Giflo-logo.png';
import { useClientPanel } from '../../providers/panel/PanelService';
import { MdArrowBack } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import {
  selectBookingRoomType,
  selectDuration,
  setBookingDuration,
  setBookingEnd,
  setBookingStart,
  setResetBookingTime,
  setSelectedBookingDate,
  setSelectedFacility,
} from '../../store/slices/booking.slice';
import { addHours, addMinutes, format, setHours, setMinutes } from 'date-fns';
import { useAppSelector } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../../store/slices/authentication.slice';

const BookingSearch: React.FC = () => {
  // const toast = useToast();
  const navigate = useNavigate();
  const panel = useClientPanel();
  const user = useAppSelector(selectUser);
  // const bookingDate = useAppSelector(selectBookingDay);
  // const bookingStart = useAppSelector(selectedStartTime);
  // const bookingEnd = useAppSelector(selectedEndTime);
  const bookingDuration = useAppSelector(selectDuration);
  const bookingRoomType = useAppSelector(selectBookingRoomType);
  // const [bookingConfirmed, setBookingConfirmed] = useState<boolean>(false);

  // const [saveBookingTrigger] = useCreateOrganizationBookingMutation();
  const [getAvailableTimeSlots] = useFetchAvailableTimeSlotsMutation();
  const dispatch = useDispatch();
  const { setLoading } = useLoading();

  const [facilityVisibility, setFacilityVisibility] = useState<string>('none');
  const [desktopResultsVisibility, setDesktopResultsVisibility] =
    useState<string>('none');

  const [availableBookingSlotResponse, setAvailableBookingSlot] =
    useState<AvailableBookingSlotResponse>();

  const handleAdd = () => {
    navigate(RoutesList.BookingConfirmation);
  };

  const handleFacilityDetails = (item: Facility) => {
    panel({
      title: 'Facility Details',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <FacilityDetails
            facility={item!}
            handleSubmit={() => {}}
            handleBooking={false}
          />
        );
      },
    });
  };

  const handleSlotSelection = (time: Time) => {
    dispatch(setBookingStart(time!));
    const duration = bookingRoomType === RoomType.BARBER ? 30 : bookingDuration;
    let date = setHours(new Date(), time?.hour ?? 0);
    date = setMinutes(date, time?.minute ?? 0);

    if (duration && duration > 10) {
      date = addMinutes(date, duration);
    } else {
      date = addHours(date, duration ?? 0);
    }

    const endTime: Time = {
      hour: date.getHours(),
      minute: date.getMinutes(),
    };
    dispatch(setBookingEnd(endTime!));
    handleAdd();
  };

  const handleMobileFacilitySlots = (item: AvailableBookingFacility) => {
    dispatch(setSelectedFacility(item.facility));
    panel({
      noTitle: true,
      size: 'lg',
      closeBtnDisplay: 'none',
      noPadding: true,
      render: (onSubmit, onCancel) => {
        return (
          <>
            <Flex
              gap={30}
              w={'full'}
              bgColor={'#222222'}
              minH={{ base: '80px', lg: '120px' }}
              justify={'center'}
              align={'center'}
              pos={'relative'}
            >
              <IconButton
                icon={<MdArrowBack />}
                onClick={() => onCancel()}
                aria-label={''}
                pos={'absolute'}
                left={5}
                bgColor={'transparent'}
                color={AppColors.tertiary}
                minH={'20px'}
                minW={'20px'}
              />
              <Image src={logo} maxW={{ base: '80px', lg: '120px' }} />
            </Flex>
            <TimeAvailabilityForm
              formDisplay="flex"
              availableBookingFacility={item}
              timeSlotSelect={(time) => {
                handleSlotSelection(time!);
                onSubmit();
              }}
              handleClubHouseEnd={async (time) => {
                dispatch(setBookingEnd(time));
                handleAdd();
                onSubmit();
              }}
            />
          </>
        );
      },
    });
  };

  const handleFacilitySlots = (item: AvailableBookingFacility) => {
    dispatch(setSelectedFacility(item.facility));

    panel({
      title: 'Facility Slots',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <TimeAvailabilityForm
            formDisplay="flex"
            availableBookingFacility={item}
            timeSlotSelect={(time) => {
              handleSlotSelection(time!);
              onSubmit();
            }}
            handleClubHouseEnd={async (time) => {
              dispatch(setBookingEnd(time));
              handleAdd();
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleFacilitySearchForm = async (
    type: RoomType,
    date: Date,
    duration: number
  ) => {
    setAvailableBookingSlot(undefined);

    if (date) {
      setLoading(true);
      const data = await getAvailableTimeSlots({
        organizationId: user?.organizationId ?? '',
        capacity: 1,
        day: format(new Date(date), 'yyyyMMdd'),
        duration: duration ?? 15,
        roomType: type,
      }).unwrap();

      setAvailableBookingSlot(data);

      setLoading(false);
    }
  };

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  return (
    <BaseLayout>
      <Flex direction={'column'} w={'full'} overflowY={'scroll'}>
        <Header backButton href={RoutesList.BookingRoomType} />
        <Flex
          direction={'column'}
          p={6}
          pb={'60px'}
          h={'auto'}
          w={'full'}
          gap={4}
        >
          <Flex w={'full'} direction={'column'}>
            <FacilitySearchForm
              onSubmit={async (formData) => {
                dispatch(setResetBookingTime());
                dispatch(setBookingDuration(formData.duration ?? 15));
                handleFacilitySearchForm(
                  bookingRoomType!,
                  formData.date ?? new Date(),
                  formData.duration ?? 15
                );
                variant === 'mobile'
                  ? setFacilityVisibility('flex')
                  : setDesktopResultsVisibility('block');
                dispatch(
                  setSelectedBookingDate(
                    formData.date?.valueOf() ?? new Date().valueOf()
                  )
                );
              }}
              handleFirstAvailableSlot={() => {
                // Implement First Available Slot API
                // handleAdd(facilities![0]);
              }}
              roomType={bookingRoomType}
            />
            {/* <AppButton
              display={displaySearch ? 'none' : 'flex'}
              borderRadius={0}
              bgColor={AppColors.tertiary}
              color={'white'}
              onClick={() => {
                handleSearchAgain();
              }}
            >
              Search Again
            </AppButton> */}
          </Flex>

          <Flex
            direction={'column'}
            gap={3}
            justify={'center'}
            align={'center'}
            bgColor={'#222222'}
            display={
              availableBookingSlotResponse &&
              availableBookingSlotResponse.availableBookingFacilities
                ?.length === 0
                ? 'flex'
                : 'none'
            }
            py={6}
          >
            <AppText
              fontSize={{ base: 'xl', lg: '4xl' }}
              fontWeight={{ base: '600', lg: 'bold' }}
              color={'white'}
            >
              No Availability
            </AppText>
            <AppText fontSize={{ base: 'md', lg: 'lg' }} color={'white'}>
              There are no slots for your selection
            </AppText>
          </Flex>
          {variant === 'mobile' ? (
            <Flex
              direction={'column'}
              gap={3}
              display={facilityVisibility}
              h={'100%'}
            >
              {availableBookingSlotResponse?.availableBookingFacilities?.map(
                (x) => {
                  return (
                    <Flex direction={'column'} gap={2} key={x.facility.id}>
                      <AppFacilityCard
                        facility={x.facility}
                        handleDetails={() => {
                          handleFacilityDetails(x.facility!);
                        }}
                        handleSlots={() => {
                          handleMobileFacilitySlots(x!);
                        }}
                      />
                    </Flex>
                  );
                }
              )}
            </Flex>
          ) : (
            <Box display={desktopResultsVisibility}>
              <Grid
                gridTemplateColumns={'repeat(3, 1fr)'}
                gridColumnGap={3}
                gridRowGap={3}
              >
                {availableBookingSlotResponse?.availableBookingFacilities?.map(
                  (x) => {
                    return (
                      <GridItem colSpan={1} h={'auto'} key={x.facility.id}>
                        <AppFacilityCard
                          facility={x.facility}
                          handleDetails={() => {
                            handleFacilityDetails(x.facility!);
                          }}
                          handleSlots={() => {
                            handleFacilitySlots(x!);
                          }}
                        />
                      </GridItem>
                    );
                  }
                )}
              </Grid>
            </Box>
          )}
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default BookingSearch;
