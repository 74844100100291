import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { AppColors } from '@giflo/shared';
import * as React from 'react';

export interface ClientSharedPanelOptions {
  catchOnCancel?: boolean;
  title?: string;
  noTitle?: boolean;
  size?: 'full' | 'xl' | 'lg' | 'md' | 'sm';
  render?: (onSubmit: () => void, onCancel: () => void) => React.ReactNode;
  noPadding?: boolean;
  closeButtonColor?: string;
  closeBtnDisplay?: string;
}

interface ClientSharedPanelProps extends ClientSharedPanelOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const ClientSharedPanel: React.FC<ClientSharedPanelProps> = ({
  open,
  title,
  noTitle = false,
  size = 'md',
  closeButtonColor = 'black',
  closeBtnDisplay = 'block',
  noPadding = false,
  render,
  onSubmit,
  onClose,
}) => {
  return (
    // <Transition.Root show={open} as={Fragment}>
    //   <Dialog
    //     as="div"
    //     static
    //     className="fixed inset-0 overflow-hidden z-40"
    //     open={open}
    //     onClose={onClose}
    //   >
    //     <div className="absolute inset-0 overflow-hidden">
    //       <Dialog.Overlay className="absolute inset-0" />

    //       <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
    //         <Transition.Child
    //           as={Fragment}
    //           enter="transform transition ease-in-out duration-500 sm:duration-700"
    //           enterFrom="translate-x-full"
    //           enterTo="translate-x-0"
    //           leave="transform transition ease-in-out duration-500 sm:duration-700"
    //           leaveFrom="translate-x-0"
    //           leaveTo="translate-x-full"
    //         >
    //           <div
    //             className={
    //               presentationStyle === 'fullScreen'
    //                 ? 'w-screen max-w-12xl'
    //                 : 'w-screen max-w-2xl'
    //             }
    //           >
    //             <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
    //               <div className="px-4 sm:px-6">
    //                 <div className="flex items-start justify-between">
    //                   <Dialog.Title className="text-lg font-medium text-gray-900">
    //                     {title}
    //                   </Dialog.Title>
    //                   <div className="ml-3 h-7 flex items-center">
    //                     <button
    //                       className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
    //                       onClick={() => onClose()}
    //                     >
    //                       <span className="sr-only">Close panel</span>
    //                       <Icon
    //                         h={'6'}
    //                         w={'6'}
    //                         mr="4"
    //                         fontSize="16"
    //                         color={'white'}
    //                         as={BiXCircle}
    //                       />
    //                     </button>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="mt-6 relative flex-1 px-4 sm:px-6">
    //                 {render && render(onSubmit, onClose)}
    //               </div>
    //             </div>
    //           </div>
    //         </Transition.Child>
    //       </div>
    //     </div>
    //   </Dialog>
    // </Transition.Root>

    <Drawer isOpen={open} placement='right' onClose={onClose} size={size}>
      <DrawerOverlay w={'full'} />
      <DrawerContent h={'100%'}>
        <DrawerCloseButton
          zIndex={5}
          color={closeButtonColor}
          display={closeBtnDisplay}
          pt={2}
        />
        {noTitle ? (
          <></>
        ) : (
          <DrawerHeader borderBottomWidth='1px'>{title}</DrawerHeader>
        )}

        <DrawerBody bgColor={AppColors.formBackground} p={noPadding ? 0 : 6}>
          {render && render(onSubmit, onClose)}
        </DrawerBody>

        {/* <DrawerFooter borderTopWidth="1px">
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue">Submit</Button>
        </DrawerFooter> */}
      </DrawerContent>
    </Drawer>
  );
};
