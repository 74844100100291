import { AppFetchBaseQuery } from './apiBase';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  Activity,
  ActivityEditForm,
  ActivityForm,
} from '@giflo/shared';

export const activityApi = createApi({
  reducerPath: 'activityApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllActivities: build.query<Activity[], any>({
      query: () => ({
        url: '/activity/active', 
        method: 'GET',
      }),
    }),
    fetchActivityById: build.query<Activity, string>({
      query: (id) => ({
        url: `/activity/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useFetchAllActivitiesQuery,
  useLazyFetchAllActivitiesQuery,
  useFetchActivityByIdQuery,
} = activityApi;
