import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../pages/login/login';
import ProtectedRoute from './protected-route';
import { AuthOutlet, RouterOutlet } from './router-outlet';
import ContactsPage from '../pages/contacts/contacts';
import UserProfile from '../pages/user-profile/user-profile';
import ChangePasswordPage from '../pages/change-password/change-password';
import BookingRoomTypes from '../pages/booking-room-types/booking-room-types';
import Bookings from '../pages/bookings/bookings';
import BookingSearch from '../pages/booking-search/booking-search';
import BookingConfirmation from '../pages/booking-confirmation/booking-confirmation';
import Activities from '../pages/activities/activities';
import FacilityBookings from '../pages/facility-booking/facility-bookings';
import { useEffect } from 'react';
import { PwaHelper } from '../helpers/pwaHelper';
import { pwaActions } from '../store/slices/pwa.slice';
import { useDispatch } from 'react-redux';

export enum RoutesList {
  Index = '/',
  Activities = '/activities',
  Bookings = '/bookings',
  BookingRoomType = '/booking/roomtype',
  BookingSearch = '/booking/search',
  BookingConfirmation = '/booking/confirmation',
  Contacts = '/contacts',
  UserProfile = '/user-profile',
  ChangePassword = '/change-password',
  FacilityBookings = '/facility/:id/bookings',
}

const Router: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeinstallprompt', (e) => {
        console.log('before install');
        e.preventDefault();
        PwaHelper.deferredPwaEvent = e;
        dispatch(pwaActions.setPwaInstalled(false));
        dispatch(pwaActions.setInstallerReady(true));
      });
      window.addEventListener('appinstalled', () => {
        dispatch(pwaActions.setPwaInstalled(true));
      });
      window
        .matchMedia('(display-mode: standalone)')
        .addEventListener('change', (evt) => {
          let displayMode = 'browser';
          if (evt.matches) {
            displayMode = 'standalone';
          }
          if (displayMode === 'browser') {
            dispatch(pwaActions.setPwaInstalled(false));
          }
          // Log display mode change to analytics
          console.log('PWA: DISPLAY_MODE_CHANGED', displayMode);
        });
    }
  });

  return (
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route path="/" element={<AuthOutlet />}>
          <Route path="/" element={<Login />} />
        </Route>
        <Route path="/" element={<RouterOutlet />}>
          <Route
            path="activities"
            element={
              <ProtectedRoute route={RoutesList.Activities}>
                <Activities />
              </ProtectedRoute>
            }
          />
          <Route
            path="bookings"
            element={
              <ProtectedRoute route={RoutesList.Bookings}>
                <Bookings />
              </ProtectedRoute>
            }
          />
          <Route
            path="contacts"
            element={
              <ProtectedRoute route={RoutesList.Contacts}>
                <ContactsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="booking/roomtype"
            element={
              <ProtectedRoute route={RoutesList.BookingRoomType}>
                <BookingRoomTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path="booking/search"
            element={
              <ProtectedRoute route={RoutesList.BookingSearch}>
                <BookingSearch />
              </ProtectedRoute>
            }
          />
          <Route
            path="booking/confirmation"
            element={
              <ProtectedRoute route={RoutesList.BookingConfirmation}>
                <BookingConfirmation />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-profile"
            element={
              <ProtectedRoute route={RoutesList.UserProfile}>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/facility/bookings/:id"
            element={
              <ProtectedRoute route={RoutesList.FacilityBookings}>
                <FacilityBookings />
              </ProtectedRoute>
            }
          />
          <Route path="change-password/:id" element={<ChangePasswordPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
