import { Flex, FlexProps } from '@chakra-ui/react';
import { AppText } from '../app-text';
import { User } from '../../models/user';
import { BiPointer } from 'react-icons/bi';

export type UserNavIconProps = {
  user: User | null;
  bgColor?: string;
  onClick: (link: any) => void;
};

export const AppUserIcon: React.FC<UserNavIconProps> = ({
  user,
  bgColor,
  onClick,
}) => {
  return (
    <Flex
      borderRadius={'100%'}
      _hover={{
        transform: 'translateY(-2px)',
        boxShadow: 'lg',
        transition: '0.2s',
        cursor: 'pointer'
      }}
      bgColor={bgColor}
      justify={'center'}
      align={'center'}
      color={'white'}
      // p={{base: 2, lg: 4}}
      onClick={onClick}
      border={`3px solid white`}
      w={'35px'}
      h={'35px'}
    >
      <AppText
        fontWeight={'600'}
      >{`${user?.firstName[0]}${user?.lastName[0]}`}</AppText>
    </Flex>
  );
};
