export class PwaHelper {
  static deferredPwaEvent: any;
  static isPWA: () => boolean = () => {
    if (!window) {
      return false;
    }
    const isStandalone = window.matchMedia(
      '(display-mode: standalone)'
    ).matches;
    if (document.referrer.startsWith('android-app://')) {
      return true;
    } else if (isStandalone) {
      return true;
    }
    return false;
  };
  static isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
}
