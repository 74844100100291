import { Flex, Image } from '@chakra-ui/react';
import { AppButton, AppText, AppColors, Booking } from '@giflo/shared';
import { format } from 'date-fns';
import { RiDeleteBin2Line, RiEyeLine } from 'react-icons/ri';
import { useFetchUserByIdQuery } from '../../../store/api/userApi';

export interface AppBookingCardProps {
  booking: Booking;
  textColor?: string;
  facilityOwner?: boolean;
  handleBookingDetails: (item: Booking) => void;
  handleDelete: (item: Booking) => void;
}

const AppBookingCard: React.FC<AppBookingCardProps> = ({
  booking,
  textColor = 'white',
  facilityOwner = false,
  handleBookingDetails,
  handleDelete,
}) => {
  const { data: user } = useFetchUserByIdQuery(booking.createdUserId!);

  return (
    <Flex
      bgColor={AppColors.formBackground}
      justify={'space-between'}
      align={{ base: 'center', lg: 'auto' }}
      borderRadius={0}
      w={'full'}
      direction={{ base: 'column', lg: 'row' }}
      gap={3}
      p={4}
      key={booking.id}
    >
      <Flex
        gap={3}
        align={{ base: 'center', lg: 'auto' }}
        direction={{ base: 'column', lg: 'row' }}
        color={textColor}
      >
        <Image
          src={booking.facility.images[0].imageUrl}
          w={{ base: '280px', lg: '180px' }}
        />
        <Flex
          direction={'column'}
          align={{ base: 'center', lg: 'start' }}
          gap={3}
          w={{ base: 'full', lg: 'auto' }}
        >
          <AppText fontSize={{ base: 'md', lg: 'xl' }}>
            {facilityOwner ? booking.organization.name : booking.facility.name}
          </AppText>
          <AppText display={facilityOwner ? 'flex' : 'none'}>
            {`${user?.firstName} ${user?.lastName}`}
          </AppText>
          <AppText fontSize={'md'}>
            {format(new Date(booking.day!), 'dd MMM yyyy')}
          </AppText>
          <AppText fontSize={'md'}>
            {`${booking.start?.hour}:${
              booking.start?.minute === 0 ? '00' : booking.start?.minute
            } - ${booking.end?.hour}:${
              booking.end?.minute === 0 ? '00' : booking.end?.minute
            }`}
          </AppText>
        </Flex>
      </Flex>

      <Flex>
        <AppText color={'white'} fontSize={'md'}>
          Created: {format(new Date(booking.dateCreated!), 'dd MMM yyyy')}
        </AppText>
      </Flex>

      <Flex
        align={'center'}
        justify={'center'}
        h={'inherit'}
        gap={3}
        display={facilityOwner ? 'none' : 'flex'}
      >
        <AppButton
          onClick={() => handleBookingDetails(booking)}
          rightIcon={<RiEyeLine />}
          borderRadius={'full'}
          color={'white'}
          bgColor={AppColors.tertiary}
        >
          View
        </AppButton>
        <AppButton
          onClick={() => handleDelete(booking)}
          rightIcon={<RiDeleteBin2Line />}
          borderRadius={'full'}
          color={'white'}
          bgColor={AppColors.tertiary}
        >
          Delete
        </AppButton>
      </Flex>
    </Flex>
  );
};

export default AppBookingCard;
