import { Flex } from '@chakra-ui/react';
import { AppText, Activity, AppButton, AppColors } from '@giflo/shared';
import AppTextRenderer from '@giflo/shared/lib/components/app-text-renderer/app-text-renderer';

export interface AppActivityCardProps {
  activity: Activity;
  clear: () => void;
}

const AppActivityCard: React.FC<AppActivityCardProps> = ({
  activity,
  clear,
}) => {
  return (
    <Flex direction={'column'} gap={8}>
      <AppText>{activity.title}</AppText>
      <AppTextRenderer markdown={activity.description} />
      <AppButton
        bgColor={AppColors.primary}
        color={'white'}
        onClick={() => clear()}
      >
        Clear Notification
      </AppButton>
    </Flex>
  );
};

export default AppActivityCard;
