import { Box, Flex, Image, Stack, useToast } from '@chakra-ui/react';
import background from '../../assets/building-login.jpeg';
import logo from '../../assets/Logo White and Blue.png';
import PasswordForm, {
  PasswordFormData,
} from '../../components/forms/change-password-form';
import { AppText, User, useLoading } from '@giflo/shared';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAuthenticatePasswordKeyQuery,
  useUpdatePasswordMutation,
  useUserAuthenticationMutation,
} from '../../store/api/authenticationApi';
import { logout, setUserAuth } from '../../store/slices/authentication.slice';
import { HttpError } from '../../models/httpError';
import { RoutesList } from '../../router/router';

const ChangePasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const { setLoading } = useLoading();

  const { data: user } = useAuthenticatePasswordKeyQuery(id ?? '');

  const [userAuthentication] = useUserAuthenticationMutation();

  const [updatePassword] = useUpdatePasswordMutation();
  const dispatch = useDispatch();
  const signIn = async (item: PasswordFormData) => {
    try {
      setLoading(true);
      await dispatch(logout());

      const userCopy: User = JSON.parse(JSON.stringify(user));
      userCopy.password = item.confirmedPassword;
      await updatePassword({
        id: user?.id ?? '',
        password: item.confirmedPassword,
      });

      const res = await userAuthentication({
        username: user!.email,
        password: item.confirmedPassword,
      }).unwrap();

      dispatch(setUserAuth(res));
      setLoading(false);
      navigate(RoutesList.Bookings);
    } catch (errResponse) {
      console.error(errResponse);
      const httpError: HttpError = (errResponse as any).data as HttpError;

      toast({
        title: 'Login Failed',
        description: httpError?.error?.message || 'Login failed.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };
  return (
    <Flex
      pos={'relative'}
      h={'100%'}
      w={'100%'}
      justify={'center'}
      align={'center'}
    >
      <Box
        bgImage={background}
        pos={'absolute'}
        w={'100vw'}
        h={'100vh'}
        bgPos={'center'}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        left={0}
        top={0}
        filter={'auto'}
        brightness={'50%'}
      />
      <Flex
        direction={'column'}
        align={'center'}
        justify={'center'}
        h={'100vh'}
      >
        <Stack position="relative" p={{ base: 4, sm: 6, md: 8 }}>
          <Box
            position="absolute"
            top={0}
            left={0}
            w="full"
            h="full"
            bg="black"
            opacity={0.5}
            bgBlendMode="multiply"
          />

          <Flex
            flexDirection={'column'}
            position="relative"
            zIndex={1}
            textAlign="center"
            display="flex"
            justifyContent="center"
          >
            <Stack mx={'auto'} maxW={'lg'} align={'center'} p={16}>
              <Image
                h={'24'}
                w={'auto'}
                src={logo}
                alt=""
                objectFit={'cover'}
              />
            </Stack>
            <AppText
              variant={'body'}
              textAlign={'center'}
              fontSize={'14px'}
              fontWeight={'600'}
              color={'white'}
            >
              Change Password
            </AppText>
            <Box w={'100%'} overflow={'hidden'}>
              <PasswordForm submitForm={(data) => signIn(data)} />
            </Box>
          </Flex>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default ChangePasswordPage;
