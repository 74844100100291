import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { Facility, Time } from '../../models';

export type AppCalendarButtonProps = {
  date?: Date;
  facility?: string;
};

export const AppCalendarButton: React.FC<AppCalendarButtonProps> = ({
  date,
  facility,
}) => {
  return (
    <AddToCalendarButton
      name='Title'
      options={[
        'Apple',
        'Google',
        'Microsoft365',
        'MicrosoftTeams',
        
      ]}
      location={'Giflo Properties'}
      startDate={date?.toDateString() ?? new Date().toISOString().slice(0, 10)}
      timeZone='Africa/Johannesburg'
    ></AddToCalendarButton>
  );
};
