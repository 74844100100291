import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  Booking,
  FacilityBookingResult,
  BookingForm,
  BookingSlot,
  AvailableBookingSlotResponse,
  AvailableBookingSlotRequest,
  OrganizationBookingCreateRequest,
  OrganizationBookingsByOptionRequest,
} from '@giflo/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationBookingApi = createApi({
  reducerPath: 'organizationBookingApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchActiveOrganizationBookingsByOrgId: build.query<Booking[], string>({
      query: (orgId) => ({
        url: `/organizationbooking/active/by/organization/${orgId}`,
        method: 'GET',
      }),
    }),
    fetchAllOrganizationBookings: build.query<
      Booking[],
      OrganizationBookingsByOptionRequest
    >({
      query: (body) => ({
        url: `/organizationbooking?facilityId=${body.facilityId}&fromToday=${body.fromToday}`,
        method: 'GET',
      }),
    }),
    fetchHistoryOrganizationBookingsByOrgId: build.query<Booking[], string>({
      query: (orgId) => ({
        url: `/organizationbooking/history/by/organization/${orgId}`,
        method: 'GET',
      }),
    }),
    fetchOrganizationBookingById: build.query<Booking, string>({
      query: (id) => ({
        url: `/organizationbooking/${id}`,
        method: 'GET',
      }),
    }),
    fetchAvailableTimeSlots: build.mutation<
      AvailableBookingSlotResponse,
      AvailableBookingSlotRequest
    >({
      query: (params) => ({
        url: '/organizationbooking/available/time/slots',
        method: 'POST',
        body: params,
      }),
    }),
    fetchFirstAvailableSlot: build.mutation<Booking, BookingSlot>({
      query: (params) => ({
        url: '/organizationbooking/available/time/slots',
        method: 'POST',
        body: params,
      }),
    }),
    createOrganizationBooking: build.mutation<
      string,
      OrganizationBookingCreateRequest
    >({
      query: (body) => ({
        url: '/organizationbooking/create',
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: string) => {
        return response;
      },
    }),
    deleteOrganizationBooking: build.mutation<string, string>({
      query: (id) => ({
        url: `/organizationbooking/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchActiveOrganizationBookingsByOrgIdQuery,
  useFetchAllOrganizationBookingsQuery,
  useLazyFetchAllOrganizationBookingsQuery,
  useLazyFetchHistoryOrganizationBookingsByOrgIdQuery,
  useFetchOrganizationBookingByIdQuery,
  useCreateOrganizationBookingMutation,
  useDeleteOrganizationBookingMutation,
  useFetchAvailableTimeSlotsMutation,
} = organizationBookingApi;
