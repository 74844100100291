import { Flex } from '@chakra-ui/react';
import { AppText, Booking, AppColors, useLoading } from '@giflo/shared';
import BaseLayout from '../../components/layouts/base-layout';
import { useEffect, useState } from 'react';
import { useLazyFetchAllOrganizationBookingsQuery } from '../../store/api/organizationBookingApi';
import { useParams } from 'react-router-dom';
import Header from '../../components/layouts/header';
import { RoutesList } from '../../router/router';
import AppBookingCard from '../../components/app/booking-card/app-booking-card';

const FacilityBookings: React.FC = () => {
  const { id } = useParams();
  const { setLoading } = useLoading();
  const [facilityBookings, setFacilityBookings] = useState<Booking[]>();
  const [getBookingsByFacility] = useLazyFetchAllOrganizationBookingsQuery();
  // const { data: bookings, refetch: refetchBookings } =
  //   useFetchAllOrganizationBookingsQuery(
  //     {
  //       facilityId: facilityId ?? '',
  //       fromToday: true,
  //     },
  //     { refetchOnMountOrArgChange: true }
  //   );

  useEffect(() => {
    if (id) {
      setupData();
    }
  }, [id]);

  const setupData = async () => {
    try {
      setLoading(true);
      const data = await getBookingsByFacility({
        facilityId: id ?? '',
        fromToday: true,
      }).unwrap();

      setFacilityBookings(data);
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <BaseLayout>
      <Flex w={'full'} h={'full'} direction={'column'}>
        <Header backButton href={RoutesList.Bookings} />
        <Flex w={'full'} direction={'column'} p={4}>
          {!facilityBookings || facilityBookings.length <= 0 ? (
            <AppText color={'white'} fontSize={'2xl'} fontWeight={'bold'}>
              No upcoming bookings
            </AppText>
          ) : (
            facilityBookings.map((x, i) => {
              return (
                <Flex mb={4}>
                  <AppBookingCard
                    key={x.id}
                    booking={x}
                    handleBookingDetails={() => {}}
                    handleDelete={() => {}}
                    facilityOwner
                  />
                </Flex>
              );
            })
          )}
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default FacilityBookings;
