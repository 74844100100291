import { AppText, AppButton, AppColors, AppLoader } from '@giflo/shared';
import { Flex, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
import { useFetchAllContactsQuery } from '../../store/api/contactsApi';
import { MdPhoneInTalk } from 'react-icons/md';
import BaseLayout from '../../components/layouts/base-layout';
import Header from '../../components/layouts/header';
import { RoutesList } from '../../router/router';

const ContactsPage: React.FC = () => {
  const { data: contacts, isLoading } = useFetchAllContactsQuery({
    refetchOnMountArgChange: true,
  });
  const gridColumns = useBreakpointValue({
    sm: 'repeat(1, 1fr)',
    lg: 'repeat(2, 1fr)',
  });
  const columnGap = useBreakpointValue({
    sm: 1,
    lg: 6,
  });
  const rowGap = useBreakpointValue({
    base: 3,
    md: 4,
    lg: 6,
  });

  return (
    <BaseLayout>
    {isLoading && <AppLoader />}
      <Flex h={'100%'} w={'full'} overflowX={'hidden'} direction={'column'}>
        <Header backButton href={RoutesList.Bookings} />
        <Flex
          w={'full'}
          h={{ base: '120%', lg: '105%' }}
          direction={'column'}
          p={{ base: 6, md: 6 }}
          gap={6}
          zIndex={5}
          pb={24}
        >
          <AppText
            fontSize={'3xl'}
            fontWeight={{ base: '600', lg: 'bold' }}
            color={'white'}
          >
            Contacts
          </AppText>
          <Grid
            gridTemplateColumns={gridColumns}
            gridColumnGap={columnGap}
            gridRowGap={rowGap}
            w={'full'}
          >
            {contacts?.map((contact, index) => {
              return (
                <GridItem
                  rowSpan={1}
                  colSpan={1}
                  w={'full'}
                  key={index}
                  h={'110px'}
                  opacity={{ base: 0.9, lg: 0.8 }}
                >
                  <Flex
                    bgColor={AppColors.formBackground}
                    boxShadow={{ base: 'md', lg: 'lg' }}
                    justify={'space-between'}
                    align={'center'}
                    p={3}
                    w={'full'}
                    h={'full'}
                    color={'white'}
                  >
                    <Flex direction={'column'} gap={3}>
                      <AppText fontSize={{ base: 'md', lg: 'lg' }}>
                        {`${contact.companyName} - ${contact.name}`}
                      </AppText>
                      <AppText
                        fontSize={{ base: 'md', lg: 'lg' }}
                        fontWeight={'bold'}
                      >
                        {contact.number}
                      </AppText>
                      <AppText
                        fontSize={{ base: 'md', lg: 'lg' }}
                        fontWeight={'bold'}
                        onClick={() =>
                          window.open(`mailto:${contact.emailAddress}`)
                        }
                      >
                        {contact.emailAddress}
                      </AppText>
                    </Flex>
                    <AppButton
                      size={{ base: 'md', md: 'md' }}
                      borderRadius={'full'}
                      onClick={() => window.open(`tel:${contact.number}`)}
                      bgColor={'green.300'}
                      filter={'auto'}
                    >
                      <MdPhoneInTalk
                        color="green"
                        width={'8px'}
                        hanging={'8px'}
                      />
                    </AppButton>
                  </Flex>
                  ;
                </GridItem>
              );
            })}
          </Grid>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default ContactsPage;
