import { createSelector, createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { PwaHelper } from '../../helpers/pwaHelper';
import { RootState } from '../store';

export interface PwaState {
  isInstalled: boolean;
  hasUserClosedBanner: boolean;
  isInstallerReady: boolean;
}

const initialState: PwaState = {
  isInstalled: false,
  hasUserClosedBanner: false,
  isInstallerReady: false,
};

export const pwaSlice = createSlice({
  name: 'pwa',
  initialState,
  reducers: {
    setHasUserClosedBanner: (state, action) => {
      state.hasUserClosedBanner = action.payload;
    },
    setPwaInstalled: (state, action) => {
      state.isInstalled = action.payload;
    },
    setInstallerReady: (state, action) => {
      state.isInstallerReady = action.payload;
    },
  },
});

export const pwaPersistConfig = {
  key: 'pwa',
  storage,
};

// Action creators are generated for each case reducer function
// export const { } = basketSlice.actions;
export const { reducer: pwaReducer, actions: pwaActions } = pwaSlice;
export const isPwaInstalled = createSelector(
  (state: RootState) => state.pwa,
  (pwaState: PwaState) => {
    return pwaState.isInstalled || PwaHelper.isPWA();
  }
);

export const getPwaState = createSelector(
  (state: RootState) => state.pwa,
  (pwaState: PwaState) => pwaState
);

export const isPwaInstallerReady = createSelector(
  (state: RootState) => state.pwa,
  (pwaState: PwaState) => pwaState.isInstallerReady
);
