import BaseLayout from '../../components/layouts/base-layout';
import { Flex, Stack, useToast, Image } from '@chakra-ui/react';
import { useEffect } from 'react';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppLoader,
  AppText,
  Booking,
  User,
  UserRole,
  useDialog,
  useLoading,
} from '@giflo/shared';
import {
  useDeleteOrganizationBookingMutation,
  useFetchActiveOrganizationBookingsByOrgIdQuery,
  useLazyFetchHistoryOrganizationBookingsByOrgIdQuery,
} from '../../store/api/organizationBookingApi';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';
import Header from '../../components/layouts/header';
import { useClientPanel } from '../../providers/panel/PanelService';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import FacilityDetails from '../../components/forms/facility-details';
import AppBookingCard from '../../components/app/booking-card/app-booking-card';
import { setUserFacilities } from '../../store/slices/facilities.slice';
import {
  useFetchAllFacilitiesQuery,
  useFetchFacilitiesByOptionsQuery,
} from '../../store/api/facilityApi';
import { AppFacilityCard } from '../../components/app/facility-card/app-facility-card';

const Bookings: React.FC = () => {
  const [deleteBookingTrigger] = useDeleteOrganizationBookingMutation();
  const [getBookingHistory] =
    useLazyFetchHistoryOrganizationBookingsByOrgIdQuery();
  const navigate = useNavigate();
  const panel = useClientPanel();
  const { setLoading } = useLoading();
  const dialog = useDialog();
  const toast = useToast();
  const user = useSelector(selectUser);

  const {
    data: bookings,
    refetch: refetchBookings,
    isLoading,
  } = useFetchActiveOrganizationBookingsByOrgIdQuery(
    user?.organizationId ?? '',
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: myFacilities, refetch: refetchMyFacilities } =
    useFetchFacilitiesByOptionsQuery(
      {
        ownerId: user && user.roleId === UserRole.ADMIN ? undefined : user?.id,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const handlePasswordChange = () => {
    dialog({
      title: 'Change Password?',
      size: 'md',
      render: (onSubmit, onCancel) => {
        return (
          <Stack spacing={4} p={4}>
            <AppText>Would you like to change your password?</AppText>
            <Flex justify={'space-between'}>
              <AppButton onClick={() => navigate(RoutesList.ChangePassword)}>
                Yes
              </AppButton>
              <AppButton onClick={() => onCancel()}>No</AppButton>
            </Flex>
          </Stack>
        );
      },
    });
  };

  const handlePasswordChangeOption = (user: User) => {
    if (user.isFirstLogin === true) {
      handlePasswordChange();
    }
  };

  const handleDeleteConfirmation = (item: Booking) => {
    dialog({
      title: 'Delete Booking',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete your booking?"
            onConfirm={async () => {
              onSubmit();
              await handleDeleteItem(item.id);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const handleDetails = (item: Booking) => {
    panel({
      title: 'Booking Details',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <FacilityDetails
            bookingDate={item.day}
            bookingStart={item.start}
            bookingEnd={item.end}
            showAddCalendar={true}
            facility={item.facility}
            handleBooking={false}
            handleSubmit={() => {}}
          />
        );
      },
    });
  };

  const handleMyFacilities = () => {
    panel({
      title: 'My Facilities',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <Flex direction={'column'} gap={3}>
            {myFacilities?.data?.map((x, i) => {
              return (
                // <Flex
                //   w={'full'}
                //   key={i}
                //   gap={3}
                //   p={4}
                //   borderRadius={0}
                //   bgColor={'#222222'}
                //   color={'white'}
                //   align={'center'}
                //   _hover={{
                //     transform: 'translateY(-3px)',
                //     transition: '0.2s',
                //   }}
                //   onClick={() => {
                //     onSubmit();
                //     navigate(`/facility/bookings/${x.id}`);
                //   }}
                // >
                //   <Image
                //     src={x.images[0].imageUrl}
                //     maxW={{ base: '80px', lg: '100px' }}
                //   />
                //   <AppText fontWeight={'500'}>{x.name}</AppText>
                // </Flex>
                <AppFacilityCard
                  key={i}
                  facility={x}
                  handleDetails={() => {}}
                  handleSlots={() => {
                    onSubmit();
                    navigate(`/facility/bookings/${x.id}`);
                  }}
                  ownerFacility={true}
                />
              );
            })}
          </Flex>
        );
      },
    });
  };

  const handleDeleteItem = async (item: string) => {
    try {
      setLoading(true);
      await deleteBookingTrigger(item ?? '').unwrap();
      setLoading(false);
    } catch (e) {
      toast({
        title: 'Booking Delete Error',
        status: 'error',
        description:
          "We've run into a problem deleting the Booking you selected. Contact support for help.",
        isClosable: true,
        duration: 9000,
      });
      setLoading(false);
    }
    toast({
      title: 'Booking Deleted',
      status: 'success',
      description: "We've deleted the Booking you selected",
      duration: 9000,
      isClosable: true,
    });
    refetchBookings();
  };

  useEffect(() => {
    handlePasswordChangeOption(user!);
  }, []);

  const handlePreviousBookings = async () => {
    setLoading(true);
    const historyItems = await getBookingHistory(
      user?.organizationId ?? ''
    ).unwrap();
    setLoading(false);
    panel({
      title: 'Last 30 days',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <Flex direction={'column'} gap={3}>
            {historyItems?.map((x) => {
              return (
                <Flex
                  gap={3}
                  direction={'column'}
                  bgColor={AppColors.tertiary}
                  color={'white'}
                  p={3}
                  key={x.id}
                >
                  <AppText>{x.day?.toString().slice(0, 10)}</AppText>
                  <AppText fontSize={'lg'} fontWeight={'500'}>
                    {x.facility.name}
                  </AppText>
                </Flex>
              );
            })}
          </Flex>
        );
      },
    });
  };

  useEffect(() => {
    if (myFacilities) {
      setUserFacilities(myFacilities.data);
    }
  }, [myFacilities]);

  return (
    <BaseLayout>
      {isLoading && <AppLoader />}
      <Flex
        direction={'column'}
        w={'full'}
        zIndex={5}
        pos={'relative'}
        gap={6}
        overflow={'hidden'}
        pb={2}
      >
        <Header />
        <Flex
          justify={'space-between'}
          px={6}
          align={{ base: 'center', lg: 'start' }}
        >
          <Flex w={'full'} gap={3} justify={'space-between'}>
            <AppText color={'white'} fontWeight="bold" fontSize="3xl">
              My Bookings
            </AppText>
          </Flex>
          <AppText
            color={AppColors.tertiary}
            fontSize={{ base: 'md', lg: 'lg' }}
            textDecoration={'underline'}
            onClick={() => handlePreviousBookings()}
            _hover={{
              cursor: 'pointer',
            }}
          >
            Previous
          </AppText>
        </Flex>

        <AppButton
          display={myFacilities?.data.length! > 0 ? 'flex' : 'none'}
          bgColor={AppColors.tertiary}
          color={'white'}
          borderRadius={0}
          onClick={() => {
            handleMyFacilities();
          }}
          maxW={'120px'}
          ml={6}
        >
          My Facilities
        </AppButton>

        <Flex
          direction={'column'}
          gap={4}
          mx={4}
          color={'white'}
          overflow={'auto'}
        >
          {bookings?.length === 0 ? (
            <AppText fontSize={{ base: 'md', lg: 'lg' }} ml={2}>
              No bookings yet
            </AppText>
          ) : (
            bookings?.map((x) => {
              return (
                <AppBookingCard
                  key={x.id}
                  booking={x}
                  handleBookingDetails={() => handleDetails(x)}
                  handleDelete={() => handleDeleteConfirmation(x)}
                />
              );
            })
          )}
        </Flex>
        <AppButton
          borderRadius={'100%'}
          h={'60px'}
          p={6}
          pos={'absolute'}
          bottom={10}
          right={5}
          color={'white'}
          bgColor={AppColors.tertiary}
          onClick={() => navigate(RoutesList.BookingRoomType)}
        >
          +
        </AppButton>
      </Flex>
    </BaseLayout>
  );
};

export default Bookings;
