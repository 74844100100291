import { Flex, Image } from '@chakra-ui/react';
import {
  AppText,
  Facility,
  AppButton,
  AppColors,
  RoomType,
} from '@giflo/shared';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { MdPeopleOutline } from 'react-icons/md';

export type FacilityCardProps = {
  facility: Facility;
  ownerFacility?: boolean;
  handleDetails: (item: Facility) => void;
  handleSlots: (item: Facility) => void;
};

export const AppFacilityCard: React.FC<FacilityCardProps> = ({
  facility,
  ownerFacility = false,
  handleDetails,
  handleSlots,
}) => {
  return (
    <Flex
      direction={'column'}
      gap={3}
      w={'full'}
      bgColor={ownerFacility ? '#222222' : AppColors.formBackground}
      minH={{ base: 'auto', lg: '420px' }}
      p={4}
      color={'white'}
      key={facility.id}
    >
      <Image
        src={facility?.images[0].imageUrl}
        w={'auto'}
        h={{ base: '210px', lg: 'inherit' }}
      />
      <Flex
        p={4}
        direction={'column'}
        w={'full'}
        gap={{ base: 3, lg: 6 }}
        px={0}
      >
        <Flex justify={'space-between'} align={'center'} w={'full'}>
          <AppText fontSize={{ base: 'md', lg: 'lg' }} color={'white'}>
            {facility?.name}
          </AppText>
          <InfoOutlineIcon
            w={'20px'}
            h={'20px'}
            onClick={() => handleDetails(facility!)}
            display={ownerFacility ? 'none' : 'flex'}
          />
        </Flex>
        <Flex gap={3} align={'center'}>
          <MdPeopleOutline />
          <AppText>{facility?.capacity ? facility?.capacity : ''}</AppText>
        </Flex>
        <Flex w={'full'} justify={'space-between'}>
          <Flex gap={3}>
            <AiOutlineClockCircle />
            <AppText>{`${facility?.start?.hour}:${
              facility?.start?.minute === 0 ? '00' : facility?.start?.minute
            } - ${facility?.end?.hour}:${
              facility?.end?.minute === 0 ? '00' : facility?.end?.minute
            }`}</AppText>
          </Flex>
        </Flex>
        <Flex gap={2}>
            {facility.availableDaysOfTheWeek?.map((x) => {
              return <AppText size={'xs'}>{x.day}</AppText>;
            })}
          </Flex>
        <AppButton
          bgColor={AppColors.tertiary}
          display={ownerFacility ? 'none' : 'flex'}
          color={'white'}
          borderRadius={0}
          onClick={() => {
            handleSlots(facility);
          }}
        >
          {facility.roomType === RoomType.CLUB_HOUSE
            ? 'Book'
            : 'View Availability'}
        </AppButton>
        <AppButton
          bgColor={AppColors.tertiary}
          display={ownerFacility ? 'flex' : 'none'}
          color={'white'}
          borderRadius={0}
          onClick={() => {
            handleSlots(facility);
          }}
        >
          View Bookings
        </AppButton>
      </Flex>
    </Flex>
  );
};
