import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Booking, Facility, Organization, RoomType } from '@giflo/shared';
import { RootState } from '../store';
import { Time } from '@giflo/shared';

interface OrganizationBookingsState {
  organization?: Organization;
  bookings?: Booking[];
  bookingDate?: number;
  bookingDuration?: number;
  bookingRoomType?: RoomType;
  facility?: Facility;
  startTime?: Time;
  endTime?: Time;
}

const initialState: OrganizationBookingsState = {
  organization: undefined,
  bookings: [],
  facility: undefined,
  bookingDate: undefined,
  bookingDuration: undefined,
  bookingRoomType: undefined,
  startTime: undefined,
  endTime: undefined,
};

//check for roomtype
//store the facility, date, start time and duration

export const bookingsSlice = createSlice({
  name: 'organizationBookings',
  initialState,
  reducers: {
    setOrganizationBookings: (state, action: PayloadAction<Booking[]>) => {
      return {
        ...state,
        bookings: action.payload,
      };
    },
    setSelectedFacility: (state, action: PayloadAction<Facility>) => {
      return {
        ...state,
        facility: action.payload,
      };
    },
    setSelectedBookingDate: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        bookingDate: action.payload,
      };
    },
    setBookingDuration: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        bookingDuration: action.payload,
      };
    },
    setBookingRoomType: (state, action: PayloadAction<RoomType>) => {
      return {
        ...state,
        bookingRoomType: action.payload,
      };
    },
    setBookingStart: (state, action: PayloadAction<Time>) => {
      return {
        ...state,
        startTime: action.payload,
      };
    },
    setBookingEnd: (state, action: PayloadAction<Time>) => {
      return {
        ...state,
        endTime: action.payload,
      };
    },
    setResetBookingTime: (state) => {
      return {
        ...state,
        endTime: initialState.endTime,
        startTime: initialState.startTime,
      };
    },
  },
});

export const {
  setOrganizationBookings,
  setBookingDuration,
  setBookingStart,
  setBookingEnd,
  setSelectedBookingDate,
  setSelectedFacility,
  setBookingRoomType,
  setResetBookingTime,
} = bookingsSlice.actions;

export const selectBookingDay = (state: RootState) =>
  state.bookings.bookingDate;
export const selectedFacility = (state: RootState) => state.bookings.facility;
export const selectDuration = (state: RootState) =>
  state.bookings.bookingDuration;
export const selectBookingRoomType = (state: RootState) =>
  state.bookings.bookingRoomType;
export const selectedStartTime = (state: RootState) => state.bookings.startTime;
export const selectedEndTime = (state: RootState) => state.bookings.endTime;
