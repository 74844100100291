import {
  Flex,
  Box,
  Stack,
  Image,
  useToast,
  Center,
  Slide,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HttpError } from '../../models/httpError';
import { RoutesList } from '../../router/router';
import { useUserAuthenticationMutation } from '../../store/api/authenticationApi';
import { setUserAuth, logout } from '../../store/slices/authentication.slice';
import logo from '../../assets/Logo White and Blue.png';
import building from '../../assets/building-login.jpeg';
import LoginForm, { LoginFormData } from '../../components/forms/login-form';
import { AppText } from '@giflo/shared';
import { useEffect, useState } from 'react';
import { setIconColor } from '../../store/slices/iconColor.slice';
import { useAppSelector } from '../../store/store';
import { getPwaState } from '../../store/slices/pwa.slice';
import PwaInstallBanner from '../../components/pwaInstallBanner';
import { PwaHelper } from '../../helpers/pwaHelper';

const Login: React.FC = () => {
  const { isInstalled, hasUserClosedBanner, isInstallerReady } =
    useAppSelector(getPwaState);
  const { isOpen, onToggle } = useDisclosure(); // or any use state boolean to toggle a popup ui
  const navigate = useNavigate();
  const toast = useToast();

  const [userAuthentication, { data, isLoading, error, isSuccess, isError }] =
    useUserAuthenticationMutation();

  const dispatch = useDispatch();
  const [currentColor, setCurrentColor] = useState<string>('');
  useEffect(() => {
    const values = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += values[Math.floor(Math.random() * 16)];
    }
    setCurrentColor(color);
  }, []);

  useEffect(() => {
    if (
      (!isInstalled &&
        !hasUserClosedBanner &&
        PwaHelper.deferredPwaEvent &&
        isInstallerReady) ||
      PwaHelper.isIos()
    ) {
      onToggle();
    }
  }, [isInstalled, hasUserClosedBanner, isInstallerReady]);

  const signIn = async (loginFormData: LoginFormData) => {
    try {
      await dispatch(logout());

      const res = await userAuthentication({
        username: loginFormData.username,
        password: loginFormData.password,
      }).unwrap();

      dispatch(setUserAuth(res));
      dispatch(setIconColor(currentColor));
      navigate(RoutesList.Bookings);
    } catch (errResponse) {
      console.error(errResponse);
      const httpError: HttpError = (errResponse as any).data as HttpError;

      toast({
        title: 'Login Failed',
        description: httpError?.error?.message || 'Login failed.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handlePwaBannerCancel = () => {
    onToggle();
  };

  return (
    <>
      <Slide direction="bottom" in={isOpen} style={{ zIndex: 10 }}>
        <PwaInstallBanner onCancel={() => handlePwaBannerCancel()} />
      </Slide>
      <Box
        bgPos={'center'}
        w={'100vw'}
        h={{ base: '110%', lg: '100%' }}
        position="absolute"
        bgImg={building}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        left={0}
        right={0}
        width="100vw"
        maxWidth="100%"
      />
      <Flex
        maxH={'100vh'}
        minW={'100vw'}
        align={'center'}
        justify={'center'}
        overflowY={'scroll'}
        direction={{ base: 'column', lg: 'row' }}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
          bg="black"
          opacity={0.5}
          bgBlendMode="multiply"
        />

        <Center
          position="relative"
          zIndex={1}
          textAlign="center"
          display="flex"
          justifyContent="center"
        >
          <Flex
            direction={'column'}
            align={'center'}
            justify={'center'}
            h={'100vh'}
          >
            <Stack position="relative" p={{ base: 4, sm: 6, md: 8 }}>
              <Box
                position="absolute"
                top={0}
                left={0}
                w="full"
                h="full"
                bg="black"
                opacity={0.5}
                bgBlendMode="multiply"
              />

              <Flex
                flexDirection={'column'}
                position="relative"
                zIndex={1}
                textAlign="center"
                display="flex"
                justifyContent="center"
              >
                <Stack mx={'auto'} w={'xs'} align={'center'} p={8}>
                  <Image
                    h={'24'}
                    w={'auto'}
                    src={logo}
                    alt=""
                    objectFit={'cover'}
                  />
                </Stack>
                <AppText
                  variant={'body'}
                  textAlign={'center'}
                  fontSize={'18px'}
                  fontWeight={'400'}
                  color={'white'}
                >
                  Booking System
                </AppText>
                <Box w={'100%'} overflow={'hidden'}>
                  <LoginForm
                    isLoading={isLoading}
                    onSubmit={signIn}
                  ></LoginForm>
                </Box>
              </Flex>
            </Stack>
          </Flex>
        </Center>
      </Flex>
    </>
  );
};

export default Login;
