import { Button } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import { Flex, Text } from '@chakra-ui/layout';
import React from 'react';
import { useDispatch } from 'react-redux';
import { FiShare } from 'react-icons/fi';
import { PwaHelper } from '../helpers/pwaHelper';
import logo from '../assets/Logo Black and Blue.png';
import { AppColors } from '@giflo/shared';
import { pwaActions } from '../store/slices/pwa.slice';

type PwaInstallBannerProps = {
  onCancel?: () => void;
};

const PwaInstallBanner: React.FC<PwaInstallBannerProps> = ({ onCancel }) => {
  const dispatch = useDispatch();

  const handleInstall = () => {
    if (PwaHelper.deferredPwaEvent) {
      PwaHelper.deferredPwaEvent.prompt();
    }
  };

  const handleCancel = () => {
    dispatch(pwaActions.setHasUserClosedBanner(true));
    if (onCancel) onCancel();
  };

  const renderIosMessage = () => {
    return (
      <>
        <Text fontSize={16} color={'gray.600'}>
          To install this app on your iphone: tap
          <span
            style={{
              marginLeft: '4px',
              marginRight: '4px',
              display: 'inline-block',
            }}
          >
            <FiShare />
          </span>
          and then "Add to homescreen"
        </Text>
      </>
    );
  };

  return (
    <Flex
      flex={1}
      shadow={'0px 0px 1px 1px #eee'}
      borderRadius={'5px'}
      p={4}
      m={{ base: 0, md: 3 }}
      direction="row"
      backgroundColor={'white'}
    >
      <Flex height={'50px'} width={'50px'} p={1} mr={4}>
        <Image
          height={{ base: '40px', md: '80px' }}
          width={'100%'}
          objectFit={'contain'}
          src={logo}
        />
      </Flex>
      <Flex direction={'column'} flex={1}>
        {PwaHelper.isIos() ? (
          renderIosMessage()
        ) : (
          <Text fontSize={16} color={'gray.600'}>
            Install for easy access to your bookings!
          </Text>
        )}
        <Flex direction={'row'} alignSelf="flex-end" mt={2}>
          {onCancel && (
            <Button
              variant={'ghost'}
              //   backgroundColor={primaryColor}
              color={'gray.600'}
              onClick={() => handleCancel()}
            >
              {PwaHelper.isIos() ? 'Okay' : 'Not now'}
            </Button>
          )}
          {!PwaHelper.isIos() && (
            <Button
              variant={'solid'}
              backgroundColor={AppColors.primary}
              color={'white'}
              onClick={handleInstall}
            >
              Install
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PwaInstallBanner;
