import { createApi } from '@reduxjs/toolkit/query/react';
import { LoginParams } from '../../models/login';
import { PasswordUpsertRequest, User, UserAuth } from '@giflo/shared';
import { AppFetchBaseQuery } from './apiBase';

export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    userAuthentication: build.mutation<UserAuth, LoginParams>({
      query: (body) => ({
        url: `/auth/login`,
        method: 'POST',
        body,
      }),
    }),
    requestPasswordResetByEmail: build.mutation<any, string>({
      query: (email) => ({
        url: `/auth/request/password/reset?email=${email}`,
        method: 'POST',
      }),
    }),
    authenticatePasswordKey: build.query<User, string>({
      query: (passwordKey) => ({
        url: `/auth/authenticate/passwordkey/${passwordKey}`,
        method: 'Get',
      }),
    }),
    updatePassword: build.mutation<any, PasswordUpsertRequest>({
      query: (body) => ({
        url: `/auth/update-password`,
        method: 'PUT',
        body: body,
      }),
    }),
  }),
});

export const {
  useUserAuthenticationMutation,
  useRequestPasswordResetByEmailMutation,
  useAuthenticatePasswordKeyQuery,
  useUpdatePasswordMutation,
} = authenticationApi;
