import * as signalR from '@microsoft/signalr';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import { useLazyFetchAllActivitiesQuery } from '../../store/api/activityApi';
import { Activity } from '@giflo/shared';
import { setCurrentActivity } from '../../store/slices/activity.slice';

const SERVER_URL = process.env.REACT_APP_API_BASE_URL;

export type SignalROptions = {
  connectionStatus: boolean;
};

export const SignalRContext = React.createContext<SignalROptions>({
  connectionStatus: false,
});

export const SignalRProvider = ({ children }: any) => {
  const [connectionStatus, setConnectionStatus] = useState(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [getActivities] = useLazyFetchAllActivitiesQuery();

  const connection = useRef<signalR.HubConnection | undefined>(undefined);

  const createConnection = () => {
    console.log('newing connection to: ', SERVER_URL);
    connection.current = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_URL}/notifications`)
      .withAutomaticReconnect()
      .build();
  };

  if (!connection.current) {
    createConnection();
  }

  useEffect(() => {
    console.log('userslice change', user);
    if (user?.id) {
      console.log('starting signal r provider');
      start();
    } else {
      console.log('stopping signal r provider');
      stop();
    }
  }, [user]);

  const handleSubscribe = async (count: number = 0) => {
    if (!connection.current) {
      createConnection();
    }

    if (connection.current?.state === signalR.HubConnectionState.Connected) {
      console.log('Closing signal R connection');
      await connection.current.stop();
    }

    console.log('Opening signal R connection');
    await connection.current?.start();

    setTimeout(async () => {
      try {
        console.log('Attempting subscribe', connection.current?.state);
        await connection.current?.invoke(
          'Subscribe',
          (user?.id || 0).toString()
        );
        console.log('subscribed');
        setConnectionStatus(true);
      } catch (error) {
        console.log('Error on invoking subscribe', error);
        if (count < 3) {
          handleSubscribe(count + 1);
        } else {
          console.error('Could not subscribe after 3 attempts');
        }
      }
    }, 1000);
  };

  const start = async () => {
    if (!connection.current) {
      createConnection();
    }

    if (connection.current?.state === signalR.HubConnectionState.Disconnected) {
      console.log('Signal R handle sub from disconnected state');
      setConnectionStatus(false);
      await handleSubscribe();
      registerEvents();
    } else {
      stop();
    }
  };

  const stop = () => {
    if (
      connection.current &&
      connection.current.state === signalR.HubConnectionState.Connected
    ) {
      console.log('Closing signal R connection');
      setConnectionStatus(true);
      connection.current.stop();
      setConnectionStatus(false);
    }
  };

  const registerEvents = () => {
    if (!connection.current) {
      createConnection();
    }

    connection?.current?.onreconnecting(() => {
      console.log('Signal R on reconnecting');
      setConnectionStatus(false);
    });
    connection?.current?.onreconnected(() => {
      console.log('Signal R on reconnected');
      setConnectionStatus(true);
    });

    connection?.current?.onclose(() => {
      console.log('Signal R on close');
      setConnectionStatus(false);
    });

    connection?.current?.on('activityNotification', (activity: any) => {
      if (activity) {
        getActivities({});
        dispatch(setCurrentActivity(JSON.parse(activity)));
      }
    });
  };

  return (
    <SignalRContext.Provider value={{ connectionStatus }} children={children} />
  );
};
