import React, { useEffect } from 'react';
import { Box, Flex, useColorModeValue, IconButton } from '@chakra-ui/react';
import { selectUser } from '../../store/slices/authentication.slice';
import { RoutesList } from '../../router/router';
import { useNavigate } from 'react-router-dom';
import { FaBell, FaCalendarAlt } from 'react-icons/fa';
import { ReactNode } from 'react';
import { MdContacts } from 'react-icons/md';
import { Activity, AppColors, AppUserIcon } from '@giflo/shared';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { selectColor } from '../../store/slices/iconColor.slice';
import { useClientDialog } from '../../providers/dialog';
import AppActivityCard from '../app/activity-card/activity-card';
import { useAppSelector } from '../../store/store';
import {
  clearCurrentActivity,
  selectActivities,
  selectCurrentActivity,
} from '../../store/slices/activity.slice';
import { useDispatch } from 'react-redux';
var packageJson = require('../../../package.json');

export type BaseLayoutProps = {
  children?: ReactNode | ReactNode[];
};

const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
  const version = packageJson.version;
  const dialog = useClientDialog();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const userColor = useAppSelector(selectColor) as string;
  const currentActivity = useAppSelector(selectCurrentActivity);
  const dispatch = useDispatch();

  const activities = useAppSelector(selectActivities);

  const activityPanel = (item: Activity) => {
    dialog({
      title: item.title,
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppActivityCard
            activity={item}
            clear={() => {
              dispatch(clearCurrentActivity());
              onCancel();
            }}
          />
        );
      },
    });
  };

  useEffect(() => {
    if (currentActivity) {
      activityPanel(currentActivity);
    }
  }, [currentActivity]);

  return (
    <Box
      h="100%"
      bg={useColorModeValue('gray.100', 'gray.900')}
      pos={'relative'}
      w={'100vw'}
      overflowX={'hidden'}
    >
      <Flex
        bg={'#222222'}
        id="base-layout-container"
        overflow={'auto'}
        w={'100%'}
        h={'100%'}
        pl={{ base: '0px', lg: '90px' }}
        pb={{ base: '60px', lg: 0 }}
      >
        {children}
      </Flex>
      <Flex
        zIndex={100}
        bottom={0}
        h={{ base: '60px', lg: 'inherit' }}
        w={{ base: '100%', lg: '90px' }}
        bgColor={AppColors.primary}
        pt={{ lg: 10 }}
        pos={'absolute'}
        alignItems={'center'}
        justifyContent={{ base: 'space-around', lg: 'start' }}
        flexDir={{ base: 'row', lg: 'column' }}
        gap={{ base: 4, lg: 16 }}
      >
        <AppUserIcon
          user={user}
          bgColor={userColor}
          onClick={() => navigate(RoutesList.UserProfile)}
        />

        <IconButton
          icon={<FaCalendarAlt />}
          aria-label={'ts'}
          bgColor={'transparent'}
          color={'white'}
          borderRadius={'lg'}
          _hover={{
            boxShadow: 'lg',
          }}
          onClick={() => navigate(RoutesList.Bookings)}
        />
        <IconButton
          icon={<MdContacts />}
          aria-label={'ts'}
          bgColor={'transparent'}
          color={'white'}
          borderRadius={'lg'}
          _hover={{
            boxShadow: 'lg',
          }}
          onClick={() => navigate(RoutesList.Contacts)}
        />
        <Box pos="relative">
          <IconButton
            icon={<FaBell />}
            aria-label={'ts'}
            bgColor={'transparent'}
            color={'white'}
            borderRadius={'lg'}
            _hover={{
              boxShadow: 'lg',
            }}
            onClick={() => navigate(RoutesList.Activities)}
          />
          {activities && activities.length > 0 ? (
            <Box className="button__badge">{activities.length}</Box>
          ) : null}
        </Box>
      </Flex>
    </Box>
  );
};

export default BaseLayout;
