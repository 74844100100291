import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { ByOptionsRequest, Facility, FacilityForm, PaginatedData } from '@giflo/shared';

export const facilityApi = createApi({
  reducerPath: 'facilityApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchFacilitiesByOptions: build.query<
    PaginatedData<Facility>,
    ByOptionsRequest
  >({
    query: (data) => ({
      url: `/facility/by/options`,
      method: 'POST',
      body: JSON.stringify(data),
    }),
    transformResponse: (response: PaginatedData<Facility>) => {
      return response;
    },
  }),
    fetchAllFacilities: build.query<Facility[], any>({
      query: () => ({
        url: '/facility',
        method: 'GET',
      }),
      transformResponse: (response: Facility[]) => {
        return response;
      },
    }),
    fetchFacilityById: build.query<Facility, string>({
      query: (id) => ({
        url: `/facility/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useFetchAllFacilitiesQuery,
  useFetchFacilitiesByOptionsQuery,
  useLazyFetchAllFacilitiesQuery,
  useFetchFacilityByIdQuery,
} = facilityApi;
