import { AppInput, AppButton, AppColors } from '@giflo/shared';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@chakra-ui/react';

export interface PasswordFormData {
  newPassword: string;
  confirmedPassword: string;
}

export const PasswordFormDefaultValues: PasswordFormData = {
  newPassword: '',
  confirmedPassword: '',
};

export const PasswordFormDataSchema = yup.object({
  newPassword: yup.string().required('Field is required'),
  confirmedPassword: yup.string().required('Field is required'),
});

type PasswordFormProps = {
  form?: PasswordFormData;
  submitForm: (data: PasswordFormData) => void;
};

const PasswordForm: React.FC<PasswordFormProps> = ({ form, submitForm }) => {
  const {
    control: passwordControl,
    formState: { isValid, errors },
    getValues,
  } = useForm<PasswordFormData>({
    defaultValues: form || PasswordFormDefaultValues,
    resolver: yupResolver(PasswordFormDataSchema),
    mode: 'onSubmit',
  });

  const saveForm = () => {
    const values = getValues();
    submitForm(values);
  };

  return (
    <Stack spacing={4} bgColor={'transparent'} color={'white'}>
      <AppInput<PasswordFormData>
        name="newPassword"
        error={errors.newPassword}
        control={passwordControl}
        label="New Password"
      />
      <AppInput<PasswordFormData>
        name="confirmedPassword"
        error={errors.confirmedPassword}
        control={passwordControl}
        label="Confirm Password"
      />
      <AppButton
        w={'full'}
        bgColor={AppColors.tertiary}
        color={'white'}
        borderRadius={0}
        mt={10}
        isDisabled={!isValid}
        onClick={() => saveForm()}
      >
        Save & Login
      </AppButton>
    </Stack>
  );
};

export default PasswordForm;
