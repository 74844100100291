import React, { useEffect, useState } from 'react';
import { Flex, useBreakpointValue, Image } from '@chakra-ui/react';
import {
  AppButton,
  AppColors,
  AppText,
  OrganizationFacilityType,
  RoomType,
  useLoading,
} from '@giflo/shared';
import BaseLayout from '../../components/layouts/base-layout';
import { RoutesList } from '../../router/router';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import Header from '../../components/layouts/header';
import { useDispatch } from 'react-redux';
import { useLazyFetchOrganizationFacilityTypesQuery } from '../../store/api/organizations';
import { useNavigate } from 'react-router-dom';
import { setBookingRoomType } from '../../store/slices/booking.slice';

const BookingRoomTypes: React.FC = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { setLoading } = useLoading();

  const [getFacilityTypes] = useLazyFetchOrganizationFacilityTypesQuery();
  const [currentFacilityTypes, setCurrentFacilityTypes] =
    useState<OrganizationFacilityType[]>();

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  const handleFetchFacilityTypes = async () => {
    setLoading(true);
    setCurrentFacilityTypes(undefined);
    const facilityTypes = await getFacilityTypes(
      user?.organizationId?.toString() ?? ''
    ).unwrap();
    setCurrentFacilityTypes(facilityTypes.data);
    setLoading(false);
  };

  useEffect(() => {
    handleFetchFacilityTypes();
  }, []);

  const handleSetupRoomType = (type: RoomType) => {
    dispatch(setBookingRoomType(type));
    navigate(RoutesList.BookingSearch);
  };

  return (
    <BaseLayout>
      <Flex direction={'column'} w={'full'} overflowY={'scroll'}>
        <Header backButton href={RoutesList.Bookings} />
        <Flex
          direction={'column'}
          p={6}
          pb={'60px'}
          h={'auto'}
          w={'full'}
          gap={4}
        >
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            gap={{ base: 3, lg: 6 }}
            w={'full'}
            justify={'space-evenly'}
          >
            {currentFacilityTypes?.map((type, i) => {
              return (
                <Flex
                  bgColor={AppColors.formBackground}
                  p={4}
                  gap={3}
                  w={'100%'}
                  direction={'column'}
                  justify={'space-between'}
                  key={i}
                >
                  <Image
                    src={type.image}
                    minW={{ base: '180px', lg: '250px' }}
                  />
                  <AppButton
                    borderRadius={0}
                    bgColor={AppColors.tertiary}
                    color={'white'}
                    onClick={() => {
                      handleSetupRoomType(type.type);
                    }}
                  >
                    {type.typeName}
                  </AppButton>
                </Flex>
              );
            })}
          </Flex>

          <Flex
            direction={'column'}
            gap={3}
            justify={'center'}
            align={'center'}
            bgColor={'#222222'}
            display={
              currentFacilityTypes && currentFacilityTypes?.length === 0
                ? 'flex'
                : 'none'
            }
            py={6}
          >
            <AppText
              fontSize={{ base: 'xl', lg: '4xl' }}
              fontWeight={{ base: '600', lg: 'bold' }}
              color={'white'}
            >
              No Facilities allocated to your organization
            </AppText>
            <AppText fontSize={{ base: 'md', lg: 'lg' }} color={'white'}>
              Please contact Giflo Support
            </AppText>
          </Flex>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default BookingRoomTypes;
