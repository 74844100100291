import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';

import type { ClientDialogModalOptions } from './dialog';
import { AppColors, AppText } from '@giflo/shared';

export const ClientDialogServiceContext = React.createContext<
  (options: ClientDialogModalOptions) => void
>(() => {
  console.error('Please ensure you register the dialog provider!');
  throw new Error('Please ensure you register the dialog provider!');
});

export const ClientDialogServiceProvider = ({ children }: any) => {
  const [dialogState, setDialogState] =
    React.useState<ClientDialogModalOptions | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const openConfirmation = (options: ClientDialogModalOptions) => {
    setDialogState(options);
    onOpen();
  };

  const handleClose = () => {
    if (dialogState?.blocking) {
      return;
    }
    setDialogState(null);
    onClose();
  };

  const handleSubmit = () => {
    setDialogState(null);
    onClose();
  };

  return (
    <>
      <ClientDialogServiceContext.Provider
        value={openConfirmation}
        children={children}
      />
      {Boolean(dialogState) && (
        <>
          <Modal isOpen={isOpen} size={dialogState?.size} onClose={onClose}>
            <ModalOverlay filter={'auto'} blur={'2px'} />
            <ModalContent p={4} bgColor={AppColors.formBackground} color={'white'}>
              <ModalHeader>{dialogState?.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {dialogState?.render ? (
                  dialogState?.render(handleSubmit, handleClose)
                ) : (
                  <AppText>No Render Method</AppText>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};
