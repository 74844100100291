import React from 'react';
import {
  Flex,
  IconButton,
  Image,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import whiteLogo from '../../assets/Logo White and Blue.png';
import blackLogo from '../../assets/Logo Black and Blue.png';
import { AppColors } from '@giflo/shared';
import { useNavigate } from 'react-router-dom';
import { MdArrowBack, MdMenu } from 'react-icons/md';

export interface HeaderProps {
  backButton?: boolean;
  href?: string;
  menuButton?: boolean;
}

const Header: React.FC<HeaderProps> = ({ backButton, menuButton, href }) => {
  const navigate = useNavigate();
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  return (
    <Flex
      gap={30}
      w={'full'}
      bgColor={useColorModeValue(AppColors.formBackground, 'black')}
      minH={{ base: '80px', lg: '120px' }}
      justify={'center'}
      align={'center'}
      pos={'relative'}
    >
      <>
        <IconButton
          display={backButton ? 'flex' : 'none'}
          icon={<MdArrowBack />}
          onClick={() => navigate(href as string)}
          aria-label={''}
          pos={'absolute'}
          left={5}
          bgColor={'transparent'}
          color={AppColors.tertiary}
          minH={'20px'}
          minW={'40px'}
        />
        <IconButton
          display={menuButton ? 'flex' : 'none'}
          icon={<MdMenu />}
          aria-label={''}
          pos={'absolute'}
          left={5}
          bgColor={'transparent'}
          color={AppColors.tertiary}
          minH={'20px'}
          minW={'40px'}
        />
      </>

      <Image
        src={useColorModeValue(whiteLogo, blackLogo)}
        maxW={{ base: '80px', lg: '120px' }}
      />
    </Flex>
  );
};

export default Header;
