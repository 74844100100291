import { Box, Flex, useBreakpointValue, Image } from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import logo from '../../assets/Giflo-logo.png';
import Header from '../../components/layouts/header';
import { RoutesList } from '../../router/router';
import { useFetchAllActivitiesQuery } from '../../store/api/activityApi';
import {
  AppButton,
  AppText,
  AppColors,
  Activity,
  AppLoader,
} from '@giflo/shared';
import { ViewIcon } from '@chakra-ui/icons';
import { useClientPanel } from '../../providers/panel/PanelService';
import AppTextRenderer from '@giflo/shared/lib/components/app-text-renderer/app-text-renderer';
import { useAppSelector } from '../../store/store';
import { selectActivities } from '../../store/slices/activity.slice';

const Activities: React.FC = () => {
  // const activities = useAppSelector(selectActivities);

  const {
    data: activities,
    refetch: refetchActivities,
    isLoading,
  } = useFetchAllActivitiesQuery({
    refetchOnMountArgChange: true,
  });

  const panel = useClientPanel();

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  const handleActivityDetails = (item: Activity) => {
    panel({
      title: item.title,
      size: 'lg',
      render: (onSubmit) => {
        return (
          <Flex direction={'column'} gap={{ base: 6, lg: 8 }} color={'white'}>
            <AppText fontSize={{ base: 'lg', lg: '2xl' }} fontWeight={'600'}>
              {item.title}
            </AppText>
            <AppTextRenderer markdown={item.description} />
          </Flex>
        );
      },
    });
  };

  return (
    <BaseLayout>
      {isLoading && <AppLoader />}
      <Flex direction={'column'} w={'full'}>
        <Header backButton href={RoutesList.Bookings} />
        <Flex w={'fill'} p={4} minH={'65px'}>
          <AppText fontSize={{ base: 'xl', lg: '2xl' }} color={'white'}>
            Activities
          </AppText>
        </Flex>
        <Flex w={'full'} direction={'column'} p={4} gap={3}>
          {activities?.map((activity, i) => {
            return (
              <Flex
                key={i}
                direction={{ base: 'column', lg: 'row' }}
                bgColor={AppColors.formBackground}
                w={'full'}
                justify={{ base: 'auto', lg: 'space-between' }}
                p={4}
                align={'center'}
              >
                <AppText fontSize={{ base: 'lg', lg: '2xl' }} color={'white'}>
                  {activity.title}
                </AppText>
                <AppButton
                  w={{ base: 'full', lg: 'auto' }}
                  mt={{ base: '8', lg: '0' }}
                  borderRadius={0}
                  bgColor={AppColors.tertiary}
                  color={'white'}
                  leftIcon={<ViewIcon />}
                  onClick={() => handleActivityDetails(activity)}
                >
                  View
                </AppButton>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </BaseLayout>
  );
};
export default Activities;
