import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Facility } from '@giflo/shared';
import { RootState } from '../store';
import { facilityApi } from '../api/facilityApi';

interface FacilityState {
  facilities: Facility[] | undefined;
  userFacilities?: Facility[]
}

const initialState: FacilityState = {
  facilities: [],
  userFacilities: []
};

export const facilitySlice = createSlice({
  name: 'facilities',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      facilityApi.endpoints.fetchAllFacilities.matchFulfilled,
      (state, { payload }) => {
        return { ...state, facilities: payload };
      }
    );
  },
  reducers: {
    setUserFacilities: (state, action: PayloadAction<Facility[]>) => {
      return {
        ...state,
        facilities: action.payload
      }
    }
  },
});

export const {
  setUserFacilities
} = facilitySlice.actions;

export const selectFacilities = (state: RootState) => state.facility.facilities;
export const selectUserFacilties = (state: RootState) => state.facility.userFacilities;